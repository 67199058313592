import { Room, RoomList, Equipment, RoomDetails } from '../types/rooms'
import { CreateTrainingRoomDto, EditTrainingRoomDto, TrainingRoomDetailsDto, TrainingRoomDto, EquipmentDto, TrainingRoomListDto } from '../api/data/types'
import { mapPreviewPhotoDto } from './common'
import { RoomFormData } from '../types/forms/data'

export function mapTrainingRoomListDto(list: TrainingRoomListDto): RoomList {
  return {
    totalCount: list.totalCount,
    trainingRooms: list.trainingRooms.map(mapTrainingRoomDto)
  }
}

export function mapTrainingRoomDto(room: TrainingRoomDto): Room {
  return {
    uuid: room.uuid,
    name: room.name.trim(),
    color: room.color,
    seats: room.seats,
    active: room.active,
    equipments: room.equipments.map(mapEquipmentDto).sort((a, b) => a.name.localeCompare(b.name)),
    photo: room.photo
  }
}

export function mapTrainingRoomDetailsDto(room: TrainingRoomDetailsDto): RoomDetails {
  return {
    uuid: room.uuid,
    name: room.name.trim(),
    color: room.color,
    seats: room.seats,
    active: room.active,
    equipments: room.equipments.map(mapEquipmentDto).sort((a, b) => a.name.localeCompare(b.name)),
    photo: room.photo
  }
}

export function mapEquipmentDto(equipment: EquipmentDto): Equipment {
  return {
    uuid: equipment.uuid,
    name: equipment.name.trim(),
    photo: mapPreviewPhotoDto(equipment.photo)
  }
}

export function mapEquipmentDtoArray(list: EquipmentDto[]): Equipment[] {
  return list.map(mapEquipmentDto)
}

export function mapRoomFormDataForCreate(form: RoomFormData, placeUuid: string): CreateTrainingRoomDto {
  return {
    name: form.name.trim(),
    color: form.color,
    seats: form.seats,
    equipments: form.equipments,
    placeUuid: placeUuid,
    photoUuid: form.photo?.original?.uuid ?? null
  }
}

export function mapRoomFormDataForEdit(form: RoomFormData): EditTrainingRoomDto {
  return {
    name: form.name.trim(),
    color: form.color,
    seats: form.seats,
    equipments: form.equipments,
    photoUuid: form.photo?.original?.uuid ?? null
  }
}

export function mapRoomDetails(room: RoomDetails): RoomFormData {
  return {
    name: room.name.trim(),
    color: room.color,
    seats: room.seats,
    equipments: room.equipments.map(item => item.uuid),
    photo: room.photo
  }
}
import { Card, CardActions, CardContent, Grid } from '@mui/material'
import { useState } from 'react'
import { Room } from '../../../types/rooms'
import TileInactiveBadge from '../../common/TileInactiveBadge'
import TileDetails from './TileDetails'
import TileEquipment from './TileEquipment'
import TileMenuSection from './TileMenuSection'
import UpdateRoomActiveStateDialog from '../../dialogs/UpdateRoomActiveStateDialog'
import TileImage from './TileImage'

type Props = {
  locationUuid: string
  room: Room
}

export default function Tile(props: Props) {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  return (
    <>
      <UpdateRoomActiveStateDialog 
        open={isDialogOpen}
        room={props.room}
        onClose={() => { setIsDialogOpen(false) }}
      />
      <Card 
        sx={{
          display: 'flex',
          position: 'relative'
        }}
      >
        {!props.room.active && <TileInactiveBadge />}
        <CardContent sx={{ flexGrow: 1, display: 'flex' }}>
          <TileImage room={props.room} />
          <Grid paddingX='.75rem'>
            <TileDetails room={props.room} />
            <TileEquipment room={props.room} />
          </Grid>
        </CardContent>
        <CardActions 
          sx={{ 
            flexDirection: 'column',
            justifyContent: 'flex-start'
          }}
        >
          <TileMenuSection
            locationUuid={props.locationUuid}
            room={props.room}
            onStateChangeClick={() => { setIsDialogOpen(true) }}
          />
        </CardActions>
      </Card>
    </>
  )
}
import { SvgIcon, SvgIconProps } from '@mui/material'

export default function TrainerIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='30px'
      height='24px'
      viewBox='0 0 30 24'
    >
      <g id="TrainerIcon" transform="translate(-9.000000, -12.000000)" fill-rule="nonzero">
        <g id="trainerIcon" transform="translate(9.000000, 12.000000)">
          <path d="M3.57073171,0.87804878 C3.57073171,0.393131707 3.1776,0 2.69268293,0 L0.87804878,0 C0.393131707,0 0,0.393131707 0,0.87804878 L0,12.5853659 C0,13.0702829 0.393131707,13.4634146 0.87804878,13.4634146 L2.69268293,13.4634146 C3.1776,13.4634146 3.57073171,13.0702829 3.57073171,12.5853659 L3.57073171,8.7804878 L5.32682927,8.7804878 L5.32682927,4.68292683 L3.57073171,4.68292683 L3.57073171,0.87804878 Z" id="Path"></path>
          <path d="M29.0926829,0 L27.2780488,0 C26.7931317,0 26.4,0.393131707 26.4,0.87804878 L26.4,4.68292683 L24.6439024,4.68292683 L24.6439024,8.7804878 L26.4,8.7804878 L26.4,12.5853659 C26.4,13.0702829 26.7931317,13.4634146 27.2780488,13.4634146 L29.0926829,13.4634146 C29.5776,13.4634146 29.9707317,13.0702829 29.9707317,12.5853659 L29.9707317,0.87804878 C29.9707317,0.393131707 29.5776,0 29.0926829,0 Z" id="Path"></path>
          <path d="M20.9121951,2.34146341 C19.8210732,2.34146341 18.9365854,3.22595122 18.9365854,4.31707317 C18.9365854,3.22595122 18.0520976,2.34146341 16.9609756,2.34146341 C15.8698537,2.34146341 14.9853659,3.22595122 14.9853659,4.31707317 C14.9853659,3.22595122 14.100878,2.34146341 13.0097561,2.34146341 C11.9186341,2.34146341 11.0341463,3.22595122 11.0341463,4.31707317 C11.0341463,3.22595122 10.1496585,2.34146341 9.05853659,2.34146341 C7.96741463,2.34146341 7.08292683,3.22595122 7.08292683,4.31707317 L7.08292683,8.00593171 C7.61549268,7.75223415 8.21086829,7.6097561 8.83902439,7.6097561 L14.6926829,7.6097561 C14.7916683,7.6097561 14.8891902,7.61572683 14.9853659,7.62638049 C16.3005659,7.7724878 17.3268293,8.89036098 17.3268293,10.2439024 L17.3268293,10.5365854 C17.3268293,10.7250146 17.3156488,10.9107512 17.2939317,11.0934439 C18.2264195,10.9351024 18.9365854,10.1239024 18.9365854,9.14634146 C18.9365854,10.2374634 19.8210732,11.1219512 20.9121951,11.1219512 C22.0033171,11.1219512 22.8878049,10.2374634 22.8878049,9.14634146 L22.8878049,4.31707317 C22.8878049,3.22595122 22.0033171,2.34146341 20.9121951,2.34146341 Z" id="Path"></path>
          <path d="M18.9365854,12.3100683 C18.363161,12.6694244 17.6861268,12.8780488 16.9609756,12.8780488 C16.9509073,12.8780488 16.9409561,12.8775805 16.9308878,12.877522 C16.2721756,14.2609171 14.8607415,15.2195122 13.2292683,15.2195122 L12.0585366,15.2195122 L12.0585366,15.4873756 C12.0585366,15.9601756 11.6952,16.3670049 11.2229268,16.3892488 C10.7186927,16.4130146 10.302439,16.0112195 10.302439,15.5121951 L10.302439,14.3414634 C10.302439,13.8565463 10.6955707,13.4634146 11.1804878,13.4634146 L12.6439024,13.4634146 C14.2603317,13.4634146 15.5707317,12.1530146 15.5707317,10.5365854 L15.5707317,10.2439024 C15.5707317,9.75898537 15.1776,9.36585366 14.6926829,9.36585366 L8.83902439,9.36585366 C7.54589268,9.36585366 6.49756098,10.4141854 6.49756098,11.7073171 L6.49756098,15.5121951 C6.49756098,17.8345756 8.31032195,19.7415805 10.595122,19.8927805 L10.595122,23.1219512 C10.595122,23.6068683 10.9882537,24 11.4731707,24 L18.497561,24 C18.982478,24 19.3756098,23.6068683 19.3756098,23.1219512 L19.3756098,19.8142244 C21.3770341,19.4062244 22.8878049,17.6323317 22.8878049,15.5121951 L22.8878049,12.3100683 C22.3143805,12.6694244 21.6373463,12.8780488 20.9121951,12.8780488 C20.1870439,12.8780488 19.5100098,12.6694244 18.9365854,12.3100683 Z" id="Path"></path>
        </g>
      </g>
    </SvgIcon>
  )
}
import { SvgIcon, SvgIconProps } from '@mui/material'

export default function AccountsIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='29px'
      height='24px'
      viewBox='0 0 29 24'
    >
      <g id="AccountsIcon" transform="translate(-10.000000, -12.000000)" fill-rule="nonzero">
        <g id="accountsIcon" transform="translate(10.000000, 12.000000)">
          <path d="M24.2084371,12.957142 L22.0203998,12.957142 C22.2434253,13.5677545 22.3652715,14.2267187 22.3652715,14.9135334 L22.3652715,23.1830502 C22.3652715,23.4694026 22.3154279,23.7442611 22.2247478,23.9998895 L25.8420604,23.9998895 C27.19331,23.9998895 28.2925782,22.9005659 28.2925782,21.5493717 L28.2925782,17.0412831 C28.2925782,14.7893111 26.4604645,12.957142 24.2084371,12.957142 Z" id="Path"></path>
          <path d="M5.92736198,14.9135887 C5.92736198,14.2267187 6.04920818,13.5678098 6.27223371,12.9571973 L4.08419637,12.9571973 C1.83216906,12.9571973 0,14.7893663 0,17.0413936 L0,21.5494822 C0,22.9006765 1.09926828,24 2.45051782,24 L6.06788574,24 C5.97720564,23.7442611 5.92736198,23.4694579 5.92736198,23.1831055 L5.92736198,14.9135887 Z" id="Path"></path>
          <path d="M16.6473966,10.8293923 L11.6452369,10.8293923 C9.39320958,10.8293923 7.56104053,12.6615613 7.56104053,14.9135887 L7.56104053,23.1831055 C7.56104053,23.6341851 7.92674492,23.9999447 8.3778798,23.9999447 L19.9147537,23.9999447 C20.3658886,23.9999447 20.731593,23.6342403 20.731593,23.1831055 L20.731593,14.9135887 C20.731593,12.6615613 18.8994239,10.8293923 16.6473966,10.8293923 Z" id="Path"></path>
          <path d="M14.1463168,0 C11.4379602,0 9.23456085,2.20339935 9.23456085,4.91181116 C9.23456085,6.74889827 10.2485092,8.35334478 11.7459189,9.19571374 C12.4561635,9.59523667 13.2749921,9.82356706 14.1463168,9.82356706 C15.0176415,9.82356706 15.8364701,9.59523667 16.5467146,9.19571374 C18.0441796,8.35334478 19.0580727,6.74884301 19.0580727,4.91181116 C19.0580727,2.20345461 16.8546733,0 14.1463168,0 Z" id="Path"></path>
          <path d="M5.52137374,4.57815702 C3.49585327,4.57815702 1.8480284,6.22598188 1.8480284,8.25150236 C1.8480284,10.2770228 3.49585327,11.9248477 5.52137374,11.9248477 C6.03517239,11.9248477 6.52443601,11.8184188 6.96888455,11.6271119 C7.7373169,11.296276 8.37091716,10.7106406 8.76298012,9.9770767 C9.03817019,9.46222814 9.19471908,8.8748797 9.19471908,8.25150236 C9.19471908,6.22603714 7.54689421,4.57815702 5.52137374,4.57815702 Z" id="Path"></path>
          <path d="M22.7712598,4.57815702 C20.7457393,4.57815702 19.0979144,6.22598188 19.0979144,8.25150236 C19.0979144,8.87493496 19.2544633,9.4622834 19.5296534,9.9770767 C19.9217163,10.7106958 20.5553166,11.2963313 21.323749,11.6271119 C21.7681975,11.8184188 22.2574611,11.9248477 22.7712598,11.9248477 C24.7967802,11.9248477 26.4446051,10.2770228 26.4446051,8.25150236 C26.4446051,6.22598188 24.7967802,4.57815702 22.7712598,4.57815702 Z" id="Path"></path>
        </g>
      </g>
    </SvgIcon>
  )
}
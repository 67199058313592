import { Button, DialogActions, DialogContent, Grid, Tooltip, Typography } from '@mui/material'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import EmptyScheduleViewImage from '../../assets/images/emptyScheduleView.webp'
import useGetReservationsOwners from '../../hooks/data/schedule/useGetReservationsOwners'
import { queryNames } from '../../hooks/queries'
import { Event, ReservationOwner } from '../../types/schedule'
import EmptyListPlaceholder from '../common/EmptyListPlaceholder'
import Loader from '../common/Loader'
import BaseDialog from '../customMui/BaseDialog'
import Link from '../customMui/Link'

type Props = {
  open: boolean
  event: Event
  onClose: () => void
}

export default function ReservationsOwnerListDialog(props: Props) {
  const texts = useTranslation('dialogs').t
  const queryClient = useQueryClient()

  const columns = texts('reservation_owner_list_columns', { returnObjects: true })
  const { data, isFetching } = useGetReservationsOwners(props.event.uuid, { enabled: props.open })

  const reservationOwnerListColumns: GridColDef<ReservationOwner>[] = useMemo(() => [
    {
      field: columns[0].name,
      headerName: columns[0].field,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      flex: 1,
      renderCell: (props) => {
        const name = `${props.row.firstName}${props.row.lastName != null ? ` ${props.row.lastName}` : ''}`
        return (
          <Tooltip title={name} >        
            <Typography 
              variant='body1' 
              sx={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
                overflow: 'hidden'
              }}
            >
              {name}
            </Typography>
          </Tooltip>
        )
      }
    },
    {
      field: columns[1].name,
      headerName: columns[1].field,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      flex: 1,
      renderCell: (props) => (
        <Tooltip title={props.row.phone} >        
          <Typography 
            variant='body1' 
            sx={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              overflow: 'hidden'
            }}
          >
            <Link to={`tel:${props.row.phone}`}> 
              {props.row.phone}
            </Link>
          </Typography>
        </Tooltip>
      )
    },
    {
      field: columns[2].name,
      headerName: columns[2].field,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      flex: 1.5,
      renderCell: (props) => (
        <Tooltip title={props.row.contactEmail} >        
          <Typography 
            variant='body1' 
            sx={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              overflow: 'hidden'
            }}
          >
            <Link to={`mailto:${props.row.contactEmail}`}> 
              {props.row.contactEmail}
            </Link>
          </Typography>
        </Tooltip>
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [])

  useEffect(() => {
    // dodane w celu pobierania zawsze aktualnych danych na otarcie dialogu
    queryClient.resetQueries([queryNames.getReservationsOwners, props.event.uuid])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  return (
    <BaseDialog
      open={props.open}
      title={texts('reservation_owner_list_title')}
      onCloseButtonClick={props.onClose}
      sx={{
        '.MuiDialog-paper': {
          minWidth: '37.5rem'
        }
      }}
    > 
      <DialogContent id='test'>
        {
          isFetching &&
            <Grid paddingY='1rem'>
              <Loader size={40} />
            </Grid>
        }
        {
          !isFetching && data!.length === 0 &&
            <Grid paddingY='1rem'>
              <EmptyListPlaceholder 
                image={EmptyScheduleViewImage}
                imageHeight='4rem'
                imageWidth='4rem'
                title={texts('reservation_owner_list_empty_list_title')}
                description={texts('reservation_owner_list_empty_list_description')}
              />
            </Grid>
        }
        {
          !isFetching && data!.length > 0 && 
            <Grid 
              sx={{ 
                height: `${((data!.length > 10 ? 10 : data!.length) * 40 + 58) / 16}rem`
              }}
            >
              <DataGridPro<ReservationOwner>
                components={{
                  Footer: () => null
                }}
                columns={reservationOwnerListColumns}
                rows={data ?? []}
                rowHeight={40}
                getRowId={(row) => row.uuid}
              />
            </Grid>
        }
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          size='medium'
          onClick={props.onClose}
          sx={{
            width: '10rem'
          }}
        >
          {texts('reservation_owner_list_close')}
        </Button>
      </DialogActions>
    </BaseDialog>
  )
}
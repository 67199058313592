import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AccountStatus } from '../../../types/common'

export enum EventType {
  EDIT_NAME,
  CHANGE_STATE
}

type Props = {
  status: AccountStatus
  onItemClick: (type: EventType) => void
}

export default function MenuButton(props: Props) {
  const texts = useTranslation('accountList').t

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Grid
        display='flex'
        justifyContent='center'
        width='100%'
      >
        <IconButton
          aria-label='more'
          id='long-button'
          aria-haspopup='true'
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget)
          }}
          sx={{
            padding: 0
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Grid>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            width: '12.5rem'
          },
        }}
      >
        <MenuItem
          disableRipple
          onClick={() => {
            handleClose()
            props.onItemClick(EventType.EDIT_NAME)
          }}
        >
          <Typography variant='body1'>
            {texts('edit_account_name_label')}
          </Typography>
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            handleClose()
            props.onItemClick(EventType.CHANGE_STATE)
          }}
        >
          <Typography variant='body1'>
            {texts('account_status_state_change_label', { status: props.status })}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  )
}


import * as Yup from 'yup'
import { TFunction, Namespace } from 'i18next'

export function roomFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    name: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .max(64, t('validations:max_64_chars')),
    seats: Yup.number()
      .required(t('validations:field_required'))
  })
}
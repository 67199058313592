import { Trans, useTranslation } from 'react-i18next'
import Dialog from '../customMui/Dialog'
import { EventPattern } from '../../types/eventPatterns'
import { PatternRepeatType } from '../../types/common'
import useRemoveEventPattern from '../../hooks/data/eventPatterns/useRemoveEventPattern'
import moment from 'moment'

type Props = {
  open: boolean
  eventPattern: EventPattern
  onClose: () => void
}

export default function RemoveEventPatternDialog(props: Props) {
  const texts = useTranslation(['dialogs', 'eventPatternList']).t

  const mutation = useRemoveEventPattern()

  return (
    <Dialog
      open={props.open}
      title={<Trans i18nKey='dialogs:remove_event_pattern_title'/>}
      description={
        <Trans 
          i18nKey='dialogs:remove_event_pattern_description'
          values={{
            startTime: props.eventPattern.pattern.startTime,
            endTime: props.eventPattern.pattern.endTime,
            repeatPattern: texts('eventPatternList:tile_pattern_row', { 
              repeatType: texts('eventPatternList:pattern_repeat_type_label', { type: props.eventPattern.pattern.repeatType }),
              weekDays: props.eventPattern.pattern.repeatType === PatternRepeatType.EVERY_WEEK
                ? props.eventPattern.pattern.weekDays.map(d => texts('eventPatternList:week_day_label', { day: d })).join(',')
                : '',
              until: props.eventPattern.pattern.repeatType !== PatternRepeatType.NO_REPEAT
                ? texts('eventPatternList:until_label', { until: moment(props.eventPattern.pattern.endedAt, 'YYYY-MM-DD').format('DD.MM.YYYY') })
                : ''
            }).trim(),
            place: texts('eventPatternList:tile_place_row', { 
              place: props.eventPattern.place.name,
              rooms: props.eventPattern.place.trainingRooms.map(r => r.name).join(', ')
            })
          }}
        />
      }
      warning={<Trans i18nKey='dialogs:remove_event_pattern_warning'/>}
      closeButtonLabel={texts('dialogs:remove_event_pattern_cancel')}
      confirmButtonLabel={texts('dialogs:remove_event_pattern_confirm')}
      loading={mutation.isLoading}
      onCloseButtonClick={() => {
        props.onClose()
      }}
      onConfirmButtonClick={() => {
        mutation.mutate({
          uuid: props.eventPattern.uuid,
        }, {
          onSettled: props.onClose
        })
      }}
    />
  )
}

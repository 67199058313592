import { PlaceRoomDto, PreviewPhotoDto, SelectedPlaceDto, UuidDto } from '../api/data/types'
import { LocationRoom, PreviewPhoto, SelectedLocation, Uuid } from '../types/common'
import { LocationSearchOption, TrainingRoomOption } from '../types/locations'

export function mapPreviewPhotoDto(photo: PreviewPhotoDto): PreviewPhoto {
  return {
    ...photo
  }
}

export function mapUuidDto(dto: UuidDto): Uuid {
  return {
    ...dto
  }
}

export function mapPlaceRoomDto(data: PlaceRoomDto): LocationRoom {
  return {
    uuid: data.uuid,
    name: data.name,
    active: data.active
  }
}

export function mapSelectedPlaceDto(loc: SelectedPlaceDto): SelectedLocation {
  return {
    uuid: loc.uuid,
    name: loc.name,
    tz: loc.tz,
    active: loc.active,
    trainingRooms: loc.trainingRooms.map(mapPlaceRoomDto)
  }
}

export function mapSelectedPlaceDtoList(loc: SelectedPlaceDto[]): SelectedLocation[] {
  return loc.map(mapSelectedPlaceDto)
}

export function mapLocationRoom(room: LocationRoom): TrainingRoomOption {
  return {
    value: room.uuid,
    label: room.name,
    active: room.active
  }
}

export function mapSelectedLocationList(data: SelectedLocation[]): LocationSearchOption[] {
  return data.map(mapSelectedLocation)
}

export function mapSelectedLocation(loc: SelectedLocation): LocationSearchOption {
  return {
    value: loc.uuid,
    label: loc.name,
    tz: loc.tz,
    active: loc.active,
    trainingRooms: loc.trainingRooms.map(mapLocationRoom)
  }
}
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import errors from './translations/errors.json'
import common from './translations/common.json'
import passwordSetup from './translations/pages/passwordSetup.json'
import resendActivation from './translations/pages/resendActivation.json'
import requestPwdReset from './translations/pages/requestPwdReset.json'
import finishPwdReset from './translations/pages/finishPwdReset.json'
import locationList from './translations/pages/locationList.json'
import locationPage from './translations/pages/locationPage.json'
import roomPage from './translations/pages/roomPage.json'
import roomList from './translations/pages/roomList.json'
import accountList from './translations/pages/accountList.json'
import createAccount from './translations/pages/createAccount.json'
import eventPatternList from './translations/pages/eventPatternList.json'
import eventPatternPage from './translations/pages/eventPatternPage.json'
import exceptionList from './translations/pages/exceptionList.json'
import exceptionPage from './translations/pages/exceptionPage.json'
import schedule from './translations/pages/schedule.json'
import trainerList from './translations/pages/trainerList.json'
import trainerPage from './translations/pages/trainerPage.json'
import login from './translations/pages/login.json'
import imgAlts from './translations/imgAlts.json'
import validations from './translations/validations.json'
import successes from './translations/successes.json'
import drawer from './translations/drawer.json'
import dialogs from './translations/dialogs.json'
import imageUpload from './translations/imageUpload.json'
import ICU from 'i18next-icu'

export const defaultNS = 'common'

export const resources = {
  pl: {
    errors,
    common,
    passwordSetup,
    imgAlts,
    validations,
    successes,
    resendActivation,
    login,
    requestPwdReset,
    finishPwdReset,
    drawer,
    locationList,
    dialogs,
    locationPage,
    imageUpload,
    roomList,
    roomPage,
    accountList,
    createAccount,
    eventPatternList,
    eventPatternPage,
    exceptionList,
    exceptionPage,
    schedule,
    trainerList,
    trainerPage
  }
}

i18n
  .use(ICU)
  .use(initReactI18next).init({
    resources,
    lng: 'pl',
    keySeparator: false,
    ns: [
      'errors',
      'common',
      'passwordSetup',
      'imgAlts',
      'validations',
      'successes',
      'resendActivation',
      'login',
      'requestPwdReset',
      'finishPwdReset',
      'drawer',
      'locationList',
      'dialogs',
      'locationPage',
      'imageUpload',
      'roomList',
      'roomPage',
      'accountList',
      'createAccount',
      'eventPatternList',
      'eventPatternPage',
      'exceptionList',
      'exceptionPage',
      'schedule',
      'trainerList',
      'trainerPage'
    ],
    defaultNS,
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['sup', 'strong']
    },
    interpolation: {
      escapeValue: false
    },
    returnNull: false
  })

export default i18n
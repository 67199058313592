import { Typography, useTheme } from '@mui/material'

type Props = {
  label: string
}

export default function Badge(props: Props) {
  const theme = useTheme()

  return (
    <Typography
      display='flex'
      alignItems='center'
      justifyContent='center'
      width='7rem'
      height='1.25rem'
      bgcolor={theme.palette.primary.main}
      borderRadius='0.25rem'
      variant='badge'
      color='white'
    >
      {props.label}
    </Typography>
  )
}
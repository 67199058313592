import { Navigate, Route } from 'react-router'
import { Outlet, Routes } from 'react-router-dom'
import MainOverlay from '../components/overlays/MainOverlay'
import { LoginState } from '../redux/storageToolkit'
import store from '../redux/store'
import FinishPwdReset from './Auth/FinishPwdReset'
import Login from './Auth/Login'
import PasswordSetup from './Auth/PasswordSetup'
import RequestPwdReset from './Auth/RequestPwdReset'
import ResendActivationEmail from './Auth/ResendActivationEmail'
import NotFoundError from './Errors/NotFoundError'
import LocationList from './Locations/LocationList'
import LocationPage from './Locations/LocationPage'
import RoomList from './Rooms/RoomList'
import RoomPage from './Rooms/RoomPage'
import AccountsList from './Accounts/AccountsList'
import CreateAccount from './Accounts/CreateAccount'
import EventPatternList from './Schedule/EventPatterns/EventPatternList'
import EventPatternPage from './Schedule/EventPatterns/EventPatternPage'
import ExceptionList from './Schedule/Exceptions/ExceptionList'
import ExceptionPage from './Schedule/Exceptions/ExceptionPage'
import SchedulePage from './Schedule/SchedulePage'
import TrainerList from './Trainers/TrainerList'
import TrainerPage from './Trainers/TrainerPage'

export default function Dashboard() {
  const sessionRoutes = () => {
    return (
      <>
        <Route path='/' element={<Outlet/>}>
          <Route index element={<SchedulePage />}/> 
          <Route path='locations' element={<Outlet/>}>
            <Route index element={<LocationList/>}/>
            <Route path='create' element={<LocationPage/>}/>
            <Route path=':uuid/edit' element={<LocationPage/>}/>
            <Route path=':uuid/rooms' element={<Outlet/>}>
              <Route index element={<RoomList/>}/>
              <Route path='create' element={<RoomPage/>}/>
              <Route path=':roomUuid/edit' element={<RoomPage/>}/>
            </Route>
          </Route>
          <Route path='login' element={<Navigate to='/' />} />
          <Route path='account' element={<Navigate to='/' />} >
            <Route path='activate' element={<Navigate to='/' />} />
            <Route path='activation/resend' element={<Navigate to='/' />} />
          </Route>
          <Route path='password/reset' element={<Navigate to='/' />} >
            <Route path='request' element={<Navigate to='/' />} />
            <Route path='finish' element={<Navigate to='/' />} />
          </Route>
          <Route path='accounts' element={<Outlet/>}>
            <Route index element={<AccountsList />}/>
            <Route path='create' element={<CreateAccount />}/>
          </Route>
          <Route path='/schedule' element={<Outlet/>}>
            <Route index element={<Navigate to='/' />}/>
            <Route path='event-patterns' element={<Outlet />}>
              <Route index element={<EventPatternList />}/>
              <Route path='create' element={<EventPatternPage />}/>
              <Route path=':uuid/edit' element={<EventPatternPage />}/>
            </Route>
            <Route path='exceptions' element={<Outlet />}>
              <Route index element={<ExceptionList />}/>
              <Route path='create' element={<ExceptionPage />}/>
              <Route path=':uuid/edit' element={<ExceptionPage />}/>
            </Route>
          </Route>
        </Route>
        <Route path='trainers' element={<Outlet/>}>
          <Route index element={<TrainerList />}/>
          <Route path=':uuid/details' element={<TrainerPage />}/>
        </Route>
        <Route path='/404' element={<NotFoundError />} />
        <Route path='*' element={<Navigate to='/404' />} />
      </>
    )
  }
  
  const authRoutes = () => {
    return (
      <>
        <Route path='/' element={<Outlet/>}>
          <Route index element={<Navigate to='/login' />} />
          <Route path='login' element={<Login/>}/>
          <Route path='account' element={<Outlet/>}>
            <Route path='activate' element={<PasswordSetup/>}/>
            <Route path='activation/resend' element={<ResendActivationEmail/>}/>
          </Route>
          <Route path='password/reset' element={<Outlet/>}>
            <Route path='request' element={<RequestPwdReset/>}/>
            <Route path='finish' element={<FinishPwdReset/>}/>
          </Route>
        </Route>
        <Route path='*' element={<Navigate to='/login' />}/>
      </>
    )
  }

  return (
    <MainOverlay>
      <Routes>
        {
          store.getState().storage.loginState === LoginState.LOGGED 
          ? sessionRoutes()
          : authRoutes()
        }
      </Routes>
    </MainOverlay>
  )
}
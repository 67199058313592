import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import AuthApi from '../../api/auth/AuthApi'
import { LoginDto } from '../../api/auth/types'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/errors'
import store from '../../redux/store'
import { logIn } from '../../redux/storageToolkit'
import { useNavigate } from 'react-router'

const authApi = AuthApi.getInstance()

export function useLogin() {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const mutation = useMutation((data: LoginDto) => authApi.login(data), {
    onSuccess: (response) => {
      store.dispatch(logIn(response.data))
      navigate('/', { replace: true })
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Grid, IconButton, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Room } from '../../../types/rooms'
import { useNavigate } from 'react-router-dom'

type Props = {
  locationUuid: string
  room: Room
  onStateChangeClick: () => void
}

export default function TileMenuSection(props: Props) {
  const texts = useTranslation('roomList').t
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Grid>
      <IconButton
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
        }}
        sx={{ color: '#000' }}
      >
        <MoreHorizIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '12rem',
          },
        }}
      >
        <MenuItem onClick={() => {
          navigate(`/locations/${props.locationUuid}/rooms/${props.room.uuid}/edit`)
        }}>
          {texts('tile_menu_edit')}
        </MenuItem>
        <MenuItem 
          onClick={() => {
            handleClose()
            props.onStateChangeClick()
          }}
        >
          {props.room.active ? texts('tile_menu_deactivate') : texts('tile_menu_activate')}
        </MenuItem>
      </Menu>
    </Grid>
  )
}
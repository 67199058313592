import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Grid, IconButton, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Event } from '../../types/schedule'
import { useNavigate } from 'react-router-dom'
import { PatternPageReferrer } from '../../types/common'

type Props = {
  event: Event
  onRemoveClick: () => void
  onOpenReservationOwnerList: () => void
}

export default function EventTileMenu(props: Props) {
  const texts = useTranslation('schedule').t
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Grid>
      <IconButton
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
        }}
        sx={{
          color: '#000',
          height: '.75rem',
          width: '1.5rem',
          opacity: 1
        }}
      >
        <MoreHorizIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '12rem',
          },
        }}
      >
        <MenuItem onClick={() => {
          handleClose()
          props.onOpenReservationOwnerList()
        }}>
          {texts('tile_menu_reservation_list')}
        </MenuItem>
        {
          !props.event.isPast &&
            <MenuItem onClick={() => {
              navigate(`/schedule/event-patterns/${props.event.pattern.uuid}/edit`, { state: { referrer: PatternPageReferrer.SCHEDULE }})
            }}>
              {texts('tile_menu_edit')}
            </MenuItem>
        }
        {
          !props.event.isPast &&
            <MenuItem onClick={() => {
              handleClose()
              props.onRemoveClick()
            }}>
              {texts('tile_menu_remove')}
            </MenuItem>
        }
      </Menu>
    </Grid>
  )
}
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { mapTrainingRoomDetailsDto } from '../../../mapping/rooms'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

export default function useGetTrainingRoomDetails(uuid: string, onError: (error: AxiosError<HttpError>) => void, enabled: boolean) {
  const texts = useTranslation().t

  const query = useQuery([queryNames.getRoomDetails, uuid], async () => {
    const { data } = await dataApi.getTrainingRoomDetails(uuid)
    return mapTrainingRoomDetailsDto(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: enabled,
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
      onError(error)
    }
  })

  return query
}

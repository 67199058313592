import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { AccountDto } from '../../../api/data/types'
import { mapAccountDto } from '../../../mapping/accounts'
import { DataSubcodes } from '../../../services/errors/consts'
import { isDataError } from '../../../services/errors/utils'
import { AccountList } from '../../../types/account'
import { HttpError, Success } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
  active: boolean
}

export default function useChangeAccountActiveState() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t

  const mutation = useMutation(async (params: MutationParams) => {
    const { data } = await dataApi.changeAccountActiveState(params.uuid, params.active)
    return data
  }, {
    onError: (error: AxiosError<HttpError>) => {
      if (isDataError(error, [DataSubcodes.ACCOUNT_NOT_FOUND, DataSubcodes.ACCOUNT_REMOVED])) {
        queryClient.resetQueries([queryNames.getAccountList])
      }
      showErrorToast(error, texts)
    },
    onSuccess: (data: Success<AccountDto>, variables: MutationParams) => {
      const queriesData = queryClient.getQueriesData<AccountList>([queryNames.getAccountList])
      for (const queryData of queriesData) {
        queryClient.setQueryData(queryData[0], (oldData?: AccountList) => {
          if (!oldData) {
            return oldData
          }
          const newData = [...oldData.accounts]
          const index = newData.findIndex(p => p.uuid === variables.uuid)
          newData[index] = mapAccountDto(data.data!)
          return {
            totalCount: oldData.totalCount,
            accounts: newData
          }
        })
      }

      toast.success(
        texts(
          variables.active 
          ? 'successes:account_activated' 
          : 'successes:account_deactivated' 
        )
      )
    }
  })

  return mutation
}

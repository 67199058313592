import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { ExceptionListOptionsDto } from '../../../api/data/types'
import { mapExceptionListDto } from '../../../mapping/exceptions'
import { HttpError } from '../../../types/common'
import { ExceptionList } from '../../../types/exceptions'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type Options = {
  enabled: boolean
  onSuccess: (data: ExceptionList) => void
}

export default function useGetExceptionList(options: ExceptionListOptionsDto & Options) {
  const texts = useTranslation().t

  const query = useQuery([queryNames.getExceptionList, options.limit, options.offset], async () => {
    const { data } = await dataApi.getExceptionList(options)
    return mapExceptionListDto(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    onSuccess: options.onSuccess,
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}

import { Clear, FilterList } from '@mui/icons-material'
import { Button, Grid, Popover, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import useSearchLocations from '../../hooks/data/locations/useSearchLocations'
import { ScheduleFilterFormData } from '../../types/forms/filter'
import { LocationSearchOption, TrainingRoomOption } from '../../types/locations'
import { timeOfDaySelectOptions } from '../../types/schedule'
import FormikExternalMultiAutocomplete from '../customMui/FormikExternalMultiAutocomplete'
import FormikMultiAutocomplete from '../customMui/FormikMultiAutocomplete'
import FormikMultiSelectField from '../customMui/FormikMultiSelectField'
import { mapSelectedLocationList } from '../../mapping/common'
import PillButton from '../customMui/PillButton'
import ButtonBadge from '../customMui/ButtonBadge'
import FormikSelectField from '../customMui/FormikSelectField'
import { LocationState, locationStateSelectOptions } from '../../types/common'
import { useQueryClient } from '@tanstack/react-query'
import { queryNames } from '../../hooks/queries'

type Props = {
  initValues?: ScheduleFilterFormData
  onFilterChange: (data: ScheduleFilterFormData) => void
  isFilterPresent: boolean
}

const defaultInitialValues: ScheduleFilterFormData = {
  locationState: LocationState.ACTIVE,
  locations: [],
  rooms: [],
  timesOfDay: []
}

export default function FilterSection(props: Props) {
  const texts = useTranslation('schedule').t
  const queryClient = useQueryClient()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    queryClient.resetQueries([queryNames.searchLocations])
    setAnchorEl(null)
  }

  const [initialValues, setInitialValues] = useState<ScheduleFilterFormData>(props.initValues ?? defaultInitialValues)
  const [selectedLocationState, setSelectedLocationState] = useState<LocationState>(props.initValues?.locationState ?? defaultInitialValues.locationState)
  const [locationSearch, setLocationSearch] = useState<string>('')

  const locationList = useSearchLocations(locationSearch, selectedLocationState === LocationState.ACTIVE ? true : undefined)

  const debounceLocationSearch = useDebouncedCallback((value) => {
    setLocationSearch(value)
    queryClient.resetQueries([queryNames.searchLocations, value])
  }, 300)

  const handleSubmit = (data: ScheduleFilterFormData) => {
    setInitialValues(data)
    props.onFilterChange(data)
    setSelectedLocationState(data.locationState)
    handleClose()
  }

  return (
    <>
      {
        props.isFilterPresent
        ? <ButtonBadge>
            <PillButton
              variant='contained'
              onClick={handleClick}
            >
              <Grid
                container
                alignItems='center'
                gap='.5rem'
              >
                <FilterList sx={{ marginTop: '-.125rem' }}/>
                {texts('filter_button_label')}
              </Grid>
            </PillButton>
          </ButtonBadge>
        : <PillButton
            variant='contained'
            onClick={handleClick}
          >
            <Grid
              container
              alignItems='center'
              gap='.5rem'
            >
              <FilterList sx={{ marginTop: '-.125rem' }}/>
              {texts('filter_button_label')}
            </Grid>
          </PillButton>
      }
      <Popover 
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setSelectedLocationState(initialValues.locationState)
          handleClose()
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '.MuiPaper-root': {
            maxHeight: 'calc(100% - 16.25rem)'
          }
        }}
      >
        <Grid
          sx={{
            padding: '1rem',
            width: '23rem'
          }}
        >
          <Typography variant='h4' marginBottom='2rem'>
            {texts('filter_popover_title')}
          </Typography>
          <Formik<ScheduleFilterFormData>
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {(formikProps) => (
              <Form>
                <Grid paddingX='.5rem'>
                  <FormikSelectField
                    name='locationState'
                    label={texts('location_state_field_label')}
                    options={locationStateSelectOptions}
                    onChange={(val) => {
                      setSelectedLocationState(val.value as LocationState)
                      formikProps.setFieldValue('locations', [])
                      formikProps.setFieldValue('rooms', [])
                    }}
                  />
                  <FormikExternalMultiAutocomplete<LocationSearchOption>
                    name='locations'
                    label={texts('filter_locations_field_label')}
                    placeholder={texts('filter_all_placeholder')}
                    shrink
                    options={mapSelectedLocationList(locationList.data ?? [])}
                    getOptionLabel={(option) => texts('inactive_option_label', { active: option.active ? 1 : 0, label: option.label })}
                    onInputChange={(event, value) => {
                      debounceLocationSearch(event.type === 'change' ? value : '')
                    }}
                    loading={locationList.isFetching}
                    onChange={(values) => {
                      formikProps.setFieldValue('rooms', formikProps.values.rooms.filter(r => values.findIndex(v => v.trainingRooms.findIndex(tr => tr.value === r.value) !== -1) !== -1))
                    }} 
                    limitTags={3}
                  />
                  <FormikMultiAutocomplete<TrainingRoomOption>
                    name='rooms'
                    label={texts('filter_rooms_field_label')}
                    placeholder={texts('filter_all_placeholder')}
                    shrink
                    getOptionLabel={(option) => texts('inactive_option_label', { active: option.active ? 1 : 0, label: option.label })}
                    disabled={formikProps.values.locations.length === 0}
                    options={formikProps.values.locations.map(e => e.trainingRooms).flat()}
                    limitTags={3}
                  />
                  <FormikMultiSelectField
                    name='timesOfDay'
                    label={texts('filter_times_of_day_field_label')}
                    options={timeOfDaySelectOptions}
                    placeholder={texts('filter_all_placeholder')}
                    shrink
                  />
                </Grid>
                <Grid marginBottom='1rem' marginTop='-1rem'>
                  <Button
                    variant='text'
                    onClick={() => {
                      formikProps.setFieldValue('locations', [])
                      formikProps.setFieldValue('rooms', [])
                      formikProps.setFieldValue('timesOfDay', [])
                    }}
                  >
                    <Grid
                      container
                      alignItems='center'
                      gap='.5rem'
                    >
                      <Clear sx={{ marginTop: '-.125rem' }}/>
                      {texts('filter_clear_button_label')}
                    </Grid>
                  </Button>
                </Grid>
                <Grid
                  container
                  justifyContent='flex-end'
                >
                  <Button
                    type='submit'
                    variant='contained'
                    sx={{ width: '10rem' }}
                  >
                    {texts('filter_confirm_button_label')}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Popover>
    </>
  )
}
import { Card, CardContent, Grid, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TrainerFormData } from '../../../types/forms/data'

type Props = {
  data: TrainerFormData
}

export default function MainSection(props: Props) {
  const texts = useTranslation('trainerPage').t

  return (
    <Grid
      container
      justifyContent='center'
    >
      <Card 
        sx={{ 
          width: '80%',
          '.MuiCardContent-root': {
            padding: '2rem',
            ':last-child': {
              padding: '2rem'
            }
          }
        }}
      >
        <CardContent>
          <Grid 
            container
            flexWrap='nowrap'
            columnGap='2rem'
          >
            <Grid
              item
              container
              flexDirection='column'
            >
              <Grid 
                container
                flexWrap='nowrap'
                columnGap='1rem'
              >
                <Grid
                  item
                  xs={6}
                >
                  <TextField 
                    label={texts('first_name_field_label')}
                    disabled
                    value={props.data.firstName}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <TextField 
                    label={texts('last_name_field_label')}
                    disabled
                    value={props.data.lastName}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <TextField 
                label={texts('nick_field_label')}
                disabled
                value={props.data.nick}
              />
              <TextField 
                label={texts('email_field_label')}
                disabled
                value={props.data.email}
              />
              <TextField 
                label={texts('phone_field_label')}
                disabled
                value={props.data.phone}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

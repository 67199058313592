import { Grid, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { queryNames } from '../../../hooks/queries'
import { trainerListPageSizes } from '../../../utils/const'
import DateRangePicker from '../../customMui/DateRangePicker'
import SearchInput from '../../customMui/SearchInput'
import { TrainerListSearchParams } from '../../../types/trainer'

export default function TopSection() {
  const texts = useTranslation('trainerList').t
  const queryClient = useQueryClient()

  const [searchParams, setSearchParams] = useQueryParams<TrainerListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    size: withDefault(NumberParam, trainerListPageSizes[0]),
    order: withDefault(StringParam, 'createdAt:desc'),
    status: withDefault(NumberParam, undefined),
    search: withDefault(StringParam, undefined),
    entriesFrom: withDefault(StringParam, moment().startOf('month').format('DD.MM.YYYY')),
    entriesTo: withDefault(StringParam, moment().format('DD.MM.YYYY')),
  })

  return (
    <Grid marginTop='2rem'>
      <Typography variant='h2'>
        {texts('title')}
      </Typography>
      <Grid 
        container
        justifyContent='space-between'
        alignItems='center'
        marginTop='1.25rem'
      >
        <Grid item xs={5}>
          <SearchInput
            placeholder={texts('search_input_placeholder')}
            initValue={searchParams.search ? decodeURI(searchParams.search) : undefined}
            onSearch={(search: string) => {
              queryClient.resetQueries([queryNames.getTrainerList, search ? encodeURI(search) : undefined])
              setSearchParams({ 
                search: search ? encodeURI(search) : undefined, 
                page: 1,
                status: undefined,
                order: 'createdAt:desc'
              }, 'replaceIn')
            }}
            onClear={() => {
              queryClient.resetQueries([queryNames.getTrainerList, undefined])
              setSearchParams({ 
                search: undefined, 
                page: 1,
                status: undefined,
                order: 'createdAt:desc'
              }, 'replaceIn')
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <DateRangePicker
            value={
              [
                searchParams.entriesFrom ? moment(searchParams.entriesFrom, 'DD.MM.YYYY').toDate() : null,
                searchParams.entriesTo ? moment(searchParams.entriesTo, 'DD.MM.YYYY').toDate() : null
              ]
            }
            maxDate={moment().toDate()}
            labels={{
              from: texts('date_range_from_label'),
              to: texts('date_range_to_label'),
              middle: texts('date_range_middle_label')
            }}
            onChange={(dates) => {
              if (dates.filter(d => d).length === 2) {
                setSearchParams({
                  entriesFrom: dates[0] != null ? moment(dates[0]).format('DD.MM.YYYY') : '',
                  entriesTo: dates[1] != null ? moment(dates[1]).format('DD.MM.YYYY') : '',
                  page: 1
                }, 'replaceIn')
              }
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
import moment from 'moment'
import { EventDto, EventPlaceDto, ReservationOwnerDto, EventTrainingRoomDto } from '../api/data/types'
import { Event, Location, TrainingRoom, ScheduleTableEntry, ReservationOwner } from '../types/schedule'
import { mapUuidDto } from './common'

export function mapEventList(data: Event[], hours: string[], dates: string[]): ScheduleTableEntry[] {
  const mappedData: ScheduleTableEntry[] = hours.map(h => {
    return {
      hour: h,
      data: dates.map(d => {
        return {
          date: d,
          data: []
        }
      })
    }
  })

  data.forEach(value => {
    const date = moment(value.start, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY')
    const time = moment(value.start, 'YYYY-MM-DD HH:mm').startOf('hour').format('HH:mm')
    const timeIdx = mappedData.findIndex(e => e.hour === time)
    if (timeIdx < 0) {
      return
    }
    const dateIdx = mappedData[timeIdx].data.findIndex(e => e.date === date)
    if (dateIdx < 0) {
      return
    }
    mappedData[timeIdx].data[dateIdx].data.push(value)
  })

  return mappedData
}

export function mapEventDtoList(data: EventDto[]): Event[] {
  return data.map(mapEventDto)
}

export function mapEventDto(data: EventDto): Event {
  return {
    uuid: data.uuid,
    takenSeats: data.takenSeats,
    totalSeats: data.totalSeats,
    start: data.start,
    end: data.end,
    isPast: data.isPast,
    place: mapEventPlaceDto(data.place),
    trainingRoom: mapEventTrainingRoomDto(data.trainingRoom),
    pattern: mapUuidDto(data.pattern)
  }
}

export function mapEventPlaceDto(data: EventPlaceDto): Location {
  return {
    ...data
  }
}

export function mapEventTrainingRoomDto(data: EventTrainingRoomDto): TrainingRoom {
  return {
    ...data
  }
}

export function mapReservationOwnerDtoList(data: ReservationOwnerDto[]): ReservationOwner[] {
  return data.map(mapReservationOwnerDto)
}

export function mapReservationOwnerDto(data: ReservationOwnerDto): ReservationOwner {
  return {
    ...data
  }
}
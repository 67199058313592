import { Box, Grid, Typography } from '@mui/material'
import EmptyListImage from '../../assets/images/emptyViewImage.webp'

type Props = {
  image?: string
  title: string
  description: string
  imageWidth?: string
  imageHeight?: string
}

export default function EmptyListPlaceholder(props: Props) {
  return (
    <Grid
      width='100%'
      container
      direction='column'
      alignItems='center'
      gap='1rem'
    >
      <Box
        alignSelf='center'
        component='img'
        src={props.image ?? EmptyListImage}
        height={props.imageHeight ?? '6.25rem'}
        width={props.imageWidth ?? '6.25rem'}
      />
      <Typography variant='h4' sx={{ textAlign: 'center' }}>
        {props.title}
      </Typography>
      <Typography variant='body1' sx={{ textAlign: 'center' }}>
        {props.description}
      </Typography>
    </Grid>
  )
}
import { Box, CSSObject, Divider, Drawer as MuiDrawer, IconButton, List, styled, Theme, Typography, Grid } from '@mui/material'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { drawerMenuItems } from '../../../utils/const'
import { isCurrentLocationInPaths } from '../../../utils/location'
import CollapseDrawerIcon from '../../icons/CollapseDrawerIcon'
import ExpandDrawerIcon from '../../icons/ExpandDrawerIcon'
import DrawerItem from './DrawerItem'
import DrawerListSection from './DrawerListSection'

const drawerOpenWidth = '18.5rem'
const drawerClosedWidth = '5rem'

const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  width: drawerOpenWidth
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: drawerClosedWidth
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      ...(open ? openedMixin(theme) : closedMixin(theme))
    }  
  }),
)

type Props = {
  expanded: boolean
  onSwitchClick: () => void
}

export default function MenuDrawer(props: Props) {
  const location = useLocation()
  const texts = useTranslation().t
  const navigate = useNavigate()

  return (
    <Box sx={{ display: 'flex', width: props.expanded ? '18.5rem' : '5rem' }}>
      <Drawer
        variant='permanent'
        open={props.expanded}
      >
        <List sx={{ padding: props.expanded ? 0 : '2rem 0 0 0' }}>
          {
            drawerMenuItems.map((section, idx) => (
              <Fragment key={`menu-section-idx-${idx}`}>
                <DrawerListSection
                  expanded={props.expanded}
                  label={texts(section.i18.text) as string}
                />
                {
                  section.items.map((item, itemIdx) => (
                    <DrawerItem 
                      key={`menu-item-idx-${itemIdx}`}
                      expanded={props.expanded}
                      icon={<item.icon />}
                      text={texts(item.i18.text) as string}
                      selected={isCurrentLocationInPaths(item.includedPaths, location)}
                      onClick={() => {
                        navigate(item.path)
                      }}
                    />
                  ))
                }
              </Fragment>
            ))
          }
        </List>
        <Divider sx={{ margin: props.expanded ? '1rem' : '2rem 0.625rem' }} />
        <IconButton
          onClick={props.onSwitchClick}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: props.expanded ? 'flex-end' : 'center',
            gap: '1.25rem',
            justifyContent: 'flex-end',
            paddingRight: props.expanded ? '1rem' : 'auto'
          }}
        >
          {
            props.expanded
            ? <CollapseDrawerIcon
                sx={{
                  height: '1.25rem',
                  width: '1.5625rem'
                }}
              />
            : <ExpandDrawerIcon
                sx={{
                  height: '1.5rem',
                  width: '1.875rem'
                }}
              />
          }
          <Typography 
            variant='minorButton'
            color='#fff'
            display={props.expanded ? 'block' : 'none'}
          >
            {texts('drawer:collapse_drawer')}
          </Typography>
        </IconButton>
        <Grid flexGrow={1}/>
        {
          props.expanded &&
            <Grid
              container
              justifyContent='center'
              marginY='2rem'
            >
              <Typography variant='menuLabel'>
                {texts('drawer:version_label', { version: process.env.REACT_APP_VERSION })}
              </Typography>
            </Grid>
        }
      </Drawer>
    </Box >
  )
}
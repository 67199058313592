import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storageReducer from './storageToolkit'

const reducer = combineReducers({
  storage: storageReducer
})

const store = configureStore({
  reducer,
  devTools: false,
})

export default store
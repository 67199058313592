import { Grid } from '@mui/material'
import MainSection from '../../components/trainers/list/MainSection'
import TopSection from '../../components/trainers/list/TopSection'
import useGaPageView from '../../hooks/google/useGaPageView'

export default function TrainerList() {
  useGaPageView('Trainers list')

  return (
    <Grid width='100%' paddingX='.5rem'>
      <TopSection />
      <MainSection />
    </Grid>
  )
}
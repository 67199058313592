export const queryNames = {
  checkActivationToken: 'checkActivationToken',
  checkResetPasswordToken: 'checkResetPasswordToken',
  getPlaceList: 'getPlaceList',
  getPlaceDetails: 'getPlaceDetails',
  getEquipmentList: 'getEquipmentList',
  getRoomList: 'getRoomList',
  getRoomDetails: 'getRoomDetails',
  getLocationList: 'getLocationList',
  getAccountList: 'getAccountList',
  searchLocations: 'searchLocations',
  getSelectedPlacesWithRooms: 'getSelectedPlacesWithRooms',
  getEventPatternList: 'getEventPatternList',
  getEventPatternDetails: 'getEventPatternDetails',
  getExceptionDetails: 'getExceptionDetails',
  getExceptionList: 'getExceptionList',
  getEventList: 'getEventList',
  getReservationsOwners: 'getEventReservations',
  getTrainerList: 'getTrainerList',
  getTrainerDetails: 'getTrainerDetails'
}

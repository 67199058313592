import { SvgIcon, SvgIconProps } from '@mui/material'

export default function LogoutIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='18px'
      height='18px'
      viewBox='0 0 18 18'
    >
      <g id="logoutIcon" fill-rule="nonzero" stroke-width="0.5">
        <path d="M12.6914062,13.78125 L12.6914062,15.1875 C12.6914062,16.7383575 11.4297638,18 9.87890625,18 L2.8125,18 C1.26164246,18 0,16.7383575 0,15.1875 L0,2.8125 C0,1.26164246 1.26164246,0 2.8125,0 L9.87890625,0 C11.4297638,0 12.6914062,1.26164246 12.6914062,2.8125 L12.6914062,4.21875 C12.6914062,4.6071167 12.3766479,4.921875 11.9882812,4.921875 C11.5999146,4.921875 11.2851562,4.6071167 11.2851562,4.21875 L11.2851562,2.8125 C11.2851562,2.03713988 10.6542664,1.40625 9.87890625,1.40625 L2.8125,1.40625 C2.03713988,1.40625 1.40625,2.03713988 1.40625,2.8125 L1.40625,15.1875 C1.40625,15.9628601 2.03713988,16.59375 2.8125,16.59375 L9.87890625,16.59375 C10.6542664,16.59375 11.2851562,15.9628601 11.2851562,15.1875 L11.2851562,13.78125 C11.2851562,13.3928833 11.5999146,13.078125 11.9882812,13.078125 C12.3766479,13.078125 12.6914062,13.3928833 12.6914062,13.78125 Z M17.4851532,7.79219054 L15.910675,6.2177124 C15.6360168,5.9430542 15.1907959,5.9430542 14.916275,6.2177124 C14.6416168,6.49223329 14.6416168,6.93745421 14.916275,7.2119751 L16.0361938,8.33203125 L7.59375,8.33203125 C7.2053833,8.33203125 6.890625,8.64678955 6.890625,9.03515625 C6.890625,9.42352295 7.2053833,9.73828125 7.59375,9.73828125 L16.0361938,9.73828125 L14.916275,10.8583374 C14.6416168,11.1328583 14.6416168,11.5780792 14.916275,11.8526001 C15.0536041,11.9899292 15.2335053,12.0585938 15.4134064,12.0585938 C15.5934448,12.0585938 15.7733459,11.9899292 15.910675,11.8526001 L17.4851532,10.278122 C18.1705627,9.5927124 18.1705627,8.4776001 17.4851532,7.79219054 Z" id="Shape"></path>
      </g>
    </SvgIcon>
  )
}
import { useField } from "formik"
import { Box, Paper, Popover } from '@mui/material'
import { useState } from 'react'
import { SketchPicker } from 'react-color'

type Props = {
  name: string
}

export default function CustomColorPicker(props: Props) {
  const [field, meta, helpers] = useField(props.name)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <Box>
      <Paper
        sx={{
          width: '2.5rem',
          height: '2.5rem',
          backgroundColor: field.value,
          '&:hover': { cursor: 'pointer' }
        }}
        onClick={(event) => {
          setIsOpen(!isOpen)
          setAnchorEl(event.currentTarget)
        }}
      />
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => { setIsOpen(false) }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <SketchPicker 
          color={field.value} 
          onChange={(color) => { 
            helpers.setValue(color.hex)
            helpers.setTouched(true)
          }} 
        />
      </Popover>
    </Box>
  )
}
import { Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../components/common/Loader'
import MainSection from '../../../components/eventPatterns/page/MainSection'
import TopSection from '../../../components/eventPatterns/page/TopSection'
import useGetEventPatternDetails from '../../../hooks/data/eventPatterns/useGetEventPatternDetails'
import useGetSelectedPlacesWithRooms from '../../../hooks/data/locations/useGetSelectedPlacesWithRooms'
import useGaPageView from '../../../hooks/google/useGaPageView'
import { queryNames } from '../../../hooks/queries'
import { mapEventPattern } from '../../../mapping/eventPatterns'
import { DataSubcodes } from '../../../services/errors/consts'
import { isDataError } from '../../../services/errors/utils'
import ConnectionError from '../../Errors/ConnectionError'

export default function EventPatternPage() {
  const texts = useTranslation('errors').t
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { uuid } = useParams<{ uuid?: string }>()

  const isEditPage = Boolean(uuid)

  useGaPageView(isEditPage ? 'Edit event pattern' : 'Add event pattern')

  const patternDetails = useGetEventPatternDetails(
    uuid ?? '',
    (error) => {
      if (isDataError(error, [DataSubcodes.EVENT_PATTERN_NOT_FOUND, DataSubcodes.EVENT_PATTERN_REMOVED])) {
        navigate(-1)
      }
    },
    isEditPage
  )

  const selectedPlace = useGetSelectedPlacesWithRooms(
    [patternDetails.data?.place.uuid ?? ''],
    (data) => {
      if (data.length === 0) {
        toast.error(texts('place_not_found'))
      }
    },
    patternDetails.data?.place.uuid != null && isEditPage
  )

  if (patternDetails.isFetching || selectedPlace.isFetching) {
    return <Loader />
  }

  if (patternDetails.isError || selectedPlace.isError) {
    return (
      <ConnectionError 
        onRefresh={() => {
          if (patternDetails.isError) {
            return queryClient.resetQueries([queryNames.getEventPatternDetails, uuid])
          }
          if (selectedPlace.isError) {
            queryClient.resetQueries([queryNames.getSelectedPlacesWithRooms])
          }
        }}
      />
    )
  }

  return (
    <Grid 
      width='100%' 
      paddingX='.5rem'
      paddingBottom='1rem'
    >
      <TopSection isEditPage={isEditPage}/>
      <MainSection 
        isEditPage={isEditPage}
        initialValues={
          isEditPage
            ? mapEventPattern(patternDetails.data!, selectedPlace.data![0]?.trainingRooms ?? [])
            : undefined
        }
      />
    </Grid>
  )
}
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TextField from '../../customMui/TextField'

export default function FormSubsection() {
  const texts = useTranslation('locationPage').t

  return (
    <>
      <TextField
        name='name'
        label={texts('name_label')}
      />
      <TextField
        name='street'
        label={texts('street_label')}
      />
      <Grid
        display='grid'
        gridTemplateColumns='2fr 3fr'
        gap='1rem'
      >
        <TextField
          name='postalCode'
          label={texts('postal_code_label')}
        />
        <TextField
          name='town'
          label={texts('town_label')}
        />
      </Grid>
      <TextField
        name='contactEmail'
        label={texts('contact_email_label')}
      />
      <TextField
        name='contactPhone'
        label={texts('contact_phone_label')}
      />
      <TextField
        name='directions'
        label={texts('directions_label')}
        multiline
        rows={3}
      />
      <TextField
        name='lockId'
        label={texts('handle_id_label')}
      />
    </>
  )
}
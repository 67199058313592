import { Card, CardActions, CardContent } from '@mui/material'
import { useState } from 'react'
import { Location } from '../../../types/locations'
import UpdateLocationActiveStateDialog from '../../dialogs/UpdateLocationActiveStateDialog'
import TileInactiveBadge from '../../common/TileInactiveBadge'
import TileDetails from './TileDetails'
import TileImage from './TileImage'
import TileMenuSection from './TileMenuSection'

type Props = {
  location: Location
}

export default function Tile(props: Props) {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  return (
    <>
      <UpdateLocationActiveStateDialog 
        open={isDialogOpen}
        location={props.location}
        onClose={() => { setIsDialogOpen(false) }}
      />
      <Card 
        sx={{
          display: 'flex',
          position: 'relative'
        }}
      >
        {!props.location.active && <TileInactiveBadge />}
        <CardContent sx={{ flexGrow: 1, display: 'flex' }}>
          <TileImage location={props.location} />
          <TileDetails location={props.location} />
        </CardContent>
        <CardActions 
          sx={{ 
            flexDirection: 'column',
            justifyContent: 'flex-start'
          }}
        >
          <TileMenuSection 
            location={props.location}
            onStateChangeClick={() => { setIsDialogOpen(true) }}
          />
        </CardActions>
      </Card>
    </>
  )
}
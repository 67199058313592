import { LoadingButton } from '@mui/lab'
import { Box, Divider, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import Link from '../../components/customMui/Link'
import TextField from '../../components/customMui/TextField'
import { useResendActivationEmail } from '../../hooks/auth/useResendActivationEmail'
import { EmailFormData } from '../../types/forms/auth'
import { emailFormDataValidation } from '../../validations/auth'
import { mapEmailFormData } from '../../mapping/auth'
import useGaPageView from '../../hooks/google/useGaPageView'

export default function ResendActivationEmail() {
  useGaPageView('Resend activation message')
  const texts = useTranslation(['common', 'resendActivation']).t
  const mutation = useResendActivationEmail()

  const handleSubmit = (data: EmailFormData) => {
    mutation.mutate(mapEmailFormData(data))
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      marginTop='5rem'
      width='100%'
    >
      <Typography variant='h1'>
        {texts('common:auth_pages_title')}
      </Typography>
      <Typography 
        variant='subtitle1'
        marginTop='1rem'
      >
        {texts('resendActivation:subtitle')}
      </Typography>
      <Formik<EmailFormData>
        initialValues={{ 
          email: ''
        }}
        onSubmit={handleSubmit}
        validateOnBlur
        validationSchema={emailFormDataValidation(texts)}
      >
        {(formikProps) => (
          <Form>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              width='24.75rem'
              sx={{
                marginTop: '2.375rem'
              }}
            >
              <TextField
                name='email'
                label={texts('resendActivation:email_label')}
              />
              <LoadingButton
                type='submit'
                variant='contained'
                loading={mutation.isLoading}
                disabled={!formikProps.dirty || !formikProps.isValid}
                sx={{
                  marginTop: '1rem',
                  width: '100%'
                }}
              >
                {texts('common:send')}
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
      <Divider 
        sx={{
          marginTop: '2.5rem', 
          width: '15rem'
        }}
      />
      <Typography 
        variant='body1'
        marginTop='1rem'
      >
        {texts('resendActivation:login_link_description')}
        <Link to='/'>
          {texts('resendActivation:login')}
        </Link>
      </Typography>
    </Box>
  )
}
import { AuthDto } from '../../api/auth/types'

export const USER_UUID = 'user_uuid'
export const USER_NAME = 'user_name'
export const USER_EMAIL = 'user_email'
export const USER_ROLES = 'user_roles'
export const USER_PERMISSIONS = 'user_permissions'

export function setUserData(data: AuthDto) {
  localStorage.setItem(USER_UUID, data.uuid)
  localStorage.setItem(USER_EMAIL, data.email)
  localStorage.setItem(USER_NAME, data.name)
  localStorage.setItem(USER_ROLES, data.roles.join(','))
  localStorage.setItem(USER_PERMISSIONS, data.permissions.join(','))
}

export function clearUserData() {
  localStorage.removeItem(USER_UUID)
  localStorage.removeItem(USER_EMAIL)
  localStorage.removeItem(USER_ROLES)
  localStorage.removeItem(USER_NAME)
  localStorage.removeItem(USER_PERMISSIONS)
}

export function setUserEmail(email: string) {
  localStorage.setItem(USER_EMAIL, email)
}

export function setUserName(name: string) {
  return localStorage.setItem(USER_NAME, name)
}

export function getUserEmail(): string | null {
  return localStorage.getItem(USER_EMAIL)
}

export function getUserName(): string | null {
  return localStorage.getItem(USER_NAME)
}

export function clearUserEmail() {
  localStorage.removeItem(USER_EMAIL)
}

export function getUserUuid(): string | null {
  return localStorage.getItem(USER_UUID)
}

export function getUserRoles(): string[] {
  return localStorage.getItem(USER_ROLES)?.split(',').filter(role => role) ?? []
}

export function getUserPermissions(): string[] {
  return localStorage.getItem(USER_PERMISSIONS)?.split(',').filter(permission => permission) ?? []
}

export function clearUserCache() {
  clearUserData()
}
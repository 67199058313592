import { LoadingButton } from '@mui/lab'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import moment from 'moment-timezone'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import useCreateException from '../../../hooks/data/exceptions/useCreateException'
import useEditException from '../../../hooks/data/exceptions/useEditException'
import useSearchLocations from '../../../hooks/data/locations/useSearchLocations'
import { mapExceptionFormDataForCreate, mapExceptionFormDataForEdit } from '../../../mapping/exceptions'
import { DataSubcodes } from '../../../services/errors/consts'
import { isDataError } from '../../../services/errors/utils'
import { ExceptionFormData } from '../../../types/forms/data'
import { LocationSearchOption, TrainingRoomOption } from '../../../types/locations'
import { exceptionFormDataValidation } from '../../../validations/exceptions'
import FormikDatePicker from '../../customMui/FormikDatePicker'
import FormikExternalAutocomplete from '../../customMui/FormikExternalAutocomplete'
import FormikMultiAutocomplete from '../../customMui/FormikMultiAutocomplete'
import { mapSelectedLocationList } from '../../../mapping/common'
import Checkbox from '../../customMui/Checkbox'
import FormikTimePicker from '../../customMui/FormikTimePicker'
import { queryNames } from '../../../hooks/queries'
import { useQueryClient } from '@tanstack/react-query'

type Props = {
  isEditPage: boolean
  initialValues?: ExceptionFormData
}

const defaultInitialValues: ExceptionFormData = {
  location: null,
  rooms: [],
  scheduledAt: moment().format('DD.MM.YYYY'),
  withTime: false,
  unavailableFrom: null,
  unavailableTo: null
}

export default function MainSection(props: Props) {
  const texts = useTranslation('exceptionPage').t
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { uuid } = useParams<{ uuid?: string }>()

  const createMutation = useCreateException()
  const editMutation = useEditException(uuid ?? '')

  const [locationSearch, setLocationSearch] = useState<string>('')

  const locationList = useSearchLocations(locationSearch)

  const debounceLocationSearch = useDebouncedCallback((value) => {
    setLocationSearch(value)
    queryClient.resetQueries([queryNames.searchLocations, value])
  }, 300)

  const handleSubmit = (data: ExceptionFormData, formikHelpers: FormikHelpers<ExceptionFormData>) => {
    if (props.isEditPage) {
      editMutation.mutate({ data: mapExceptionFormDataForEdit(data) }, {
        onSuccess: () => {
          formikHelpers.resetForm({ values: data })
        },
        onError: (error) => {
          if (isDataError(error, DataSubcodes.EXCEPTION_NOT_FOUND)) {
            navigate(-1)
          }
        }
      })
    } else {
      createMutation.mutate({
        data: mapExceptionFormDataForCreate(data)
      }, {
        onSuccess: () => {
          navigate('/schedule/exceptions', { replace: true })
        },
      })
    }
  }

  return (
    <Grid
      container
      justifyContent='center'
    >
      <Card 
        sx={{ 
          width: '80%',
          '.MuiCardContent-root': {
            padding: '2rem',
            ':last-child': {
              padding: '2rem'
            }
          }
        }}
      >
        <CardContent>
          <Formik<ExceptionFormData> 
            initialValues={props.initialValues ?? defaultInitialValues}
            onSubmit={handleSubmit}
            validationSchema={exceptionFormDataValidation(texts)}
          >
            {(formikProps) => (
              <Form>
                <FormikExternalAutocomplete<LocationSearchOption>
                  name='location'
                  disabled={props.isEditPage}
                  options={mapSelectedLocationList(locationList.data ?? [])}
                  label={texts('location_field_label')}
                  loading={locationList.isFetching}
                  onInputChange={(event, value) => {
                    if (event) {
                      debounceLocationSearch(event.type === 'change' ? value : '')
                    }
                  }}
                  getOptionLabel={(option) => texts('inactive_option_label', { active: option.active ? 1 : 0, label: option.label })}
                  onChange={() => { formikProps.setFieldValue('rooms', []) }} 
                />
                <FormikMultiAutocomplete<TrainingRoomOption>
                  name='rooms'
                  label={texts('rooms_field_label')}
                  disabled={!formikProps.values.location}
                  options={formikProps.values.location?.trainingRooms ?? []}
                  getOptionLabel={(option) => texts('inactive_option_label', { active: option.active ? 1 : 0, label: option.label })}

                />
                <Typography
                  variant='body1'
                  fontWeight={500}
                  marginBottom='1rem'
                >
                  {texts('date_section_title')}
                </Typography>
                <Grid xs={4}>
                  <FormikDatePicker
                    name='scheduledAt'
                    disabled={props.isEditPage}
                    label={texts('scheduled_at_field_label')}
                    minDate={moment().tz(formikProps.values.location?.tz ?? process.env.REACT_APP_DEFAULT_TZ!).toDate()}
                  />
                </Grid>
                <Grid marginTop='-1rem'>
                  <Checkbox
                    name='withTime'
                    label={texts('with_time_checkbox_label')}
                    alignItems='center'
                    disabled={props.isEditPage}
                    sx={{
                      checkbox: {
                        paddingBlock: 0
                      }
                    }}
                  />
                </Grid>
                {
                  formikProps.values.withTime &&
                    <> 
                      <Typography
                        variant='body1'
                        fontWeight={500}
                        marginBottom='1rem'
                      >
                        {texts('hours_section_title')}
                      </Typography>
                      <Grid 
                        container
                        columnGap='.5rem'
                        flexWrap='nowrap'
                      >
                        <Grid item xs={4}>
                          <FormikTimePicker
                            name='unavailableFrom'
                            label={texts('unavailable_from_label')}
                            disabled={props.isEditPage}
                            shrink
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormikTimePicker
                            name='unavailableTo'
                            label={texts('unavailable_to_label')}
                            disabled={props.isEditPage}
                            shrink
                          />
                        </Grid>
                      </Grid>
                    </>
                }
                <Grid
                  container
                  justifyContent='flex-end'
                >
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    disabled={!formikProps.dirty || !formikProps.isValid}
                    loading={createMutation.isLoading || editMutation.isLoading}
                    sx={{
                      width: '10rem'
                    }}
                  >
                    {texts(props.isEditPage ? 'save_button' : 'add_button')}
                  </LoadingButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Grid>
  )
}
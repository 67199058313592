import { Grid } from '@mui/material'
import { GridColDef, GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DecodedValueMap, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import useGetTrainerList from '../../../hooks/data/trainers/useGetTrainerList'
import { queryNames } from '../../../hooks/queries'
import { TrainerItem, TrainerListSearchParams, TrainerStatus } from '../../../types/trainer'
import { contentContainerId, trainerListPageSizes } from '../../../utils/const'
import ListConnectionError from '../../common/ListConnectionError'
import DataGrid from '../../customMui/DataGrid'
import { mapTrainerListFilterModel, mapTrainerListSortModel } from '../../customMui/utils'
import MenuButton from './MenuButton'
import NoTrainerFound from './NoTrainerFound'

function isSearchParamsValid(params: DecodedValueMap<TrainerListSearchParams>, columns: GridColDef<TrainerItem>[]): boolean {
  return Number.isInteger(params.page) // must be a number, not a NaN
    && params.page >= 1  // page cannot be lower than 1
    && Number.isInteger(params.size)  // must be a number, not a NaN
    && trainerListPageSizes.includes(params.size) // selected size must be included in defined list
    && (params.status == null || params.status in TrainerStatus) // status must be null or included in enum
    && (
      !Boolean(params.order) // no sort order defined
      || (
        columns.find(c => c.field === params.order.split(':')[0]) != null // first part of order string matches column
        && columns.find(c => c.field === params.order.split(':')[0])?.sortable === true // first part of order string must match sortable column
        && ['asc', 'desc'].includes(params.order.split(':')[1]?.toLowerCase()) // second part of sort string must be a sort order string
      ) 
    )
    && moment(params.entriesFrom, 'DD.MM.YYYY').isValid()
    && moment(params.entriesTo, 'DD.MM.YYYY').isValid()
}

const defaultOrder = 'createdAt:desc'

export default function MainSection() {
  const texts = useTranslation(['common', 'trainerList']).t
  const queryClient = useQueryClient()

  const [searchParams, setSearchParams] = useQueryParams<TrainerListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    size: withDefault(NumberParam, trainerListPageSizes[0]),
    order: withDefault(StringParam, defaultOrder),
    status: withDefault(NumberParam, undefined),
    search: withDefault(StringParam, undefined),
    entriesFrom: withDefault(StringParam, moment().startOf('month').format('DD.MM.YYYY')),
    entriesTo: withDefault(StringParam, moment().format('DD.MM.YYYY')),
  })

  const handleSortChange = (model: GridSortModel) => {
    if (searchParams.order === defaultOrder && model.length === 0) {
      return setSearchParams(
        {
          order: 'createdAt:asc',
          page: 1
        }, 'replaceIn'
      )
    } 
    setSearchParams(
      {
        order: model.length > 0
          ? model.map(entry => `${entry.field}:${entry.sort}`).join(',')
          : defaultOrder,
        page: 1
      }, 'replaceIn'
    )
  }

  const handleFilterChange = (model: GridFilterModel) => {
    const filterChanged = (model.items.length === 0 || model.items[0].value != null) && searchParams.status !== model.items[0].value
    setSearchParams({
      status: model.items.length > 0 && model.items[0].value != null && model.items[0].columnField === 'status'
        ? model.items[0].value
        : undefined,
      ...(filterChanged ? { page: 1 } : {})
    }, 'replaceIn')
  }

  const columns = texts('trainerList:table_columns', { returnObjects: true })
  const trainerListColumns: GridColDef<TrainerItem>[] = useMemo(() => [
    {
      field: columns[0].name,
      headerName: columns[0].field,
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      flex: 3,
      valueGetter: (props) => `${props.row.lastName} ${props.row.firstName}`
    },
    {
      field: columns[1].name,
      headerName: columns[1].field,
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      flex: 0.9,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (props) => props.row.traineesCount ? props.row.traineesCount : '-'
    },
    {
      field: columns[2].name,
      headerName: columns[2].field,
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      align: 'center',
      headerAlign: 'center',
      flex: 0.9,
      valueGetter: (props) => props.row.entriesCount ? props.row.entriesCount : '-'
    },
    {
      field: columns[3].name,
      headerName: columns[3].field,
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      flex: 1.5,
      valueGetter: (props) => moment(props.row.createdAt).format('DD.MM.YYYY HH:mm')
    },
    {
      field: columns[4].name,
      headerName: columns[4].field,
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      flex: 1.8,
      valueGetter: (props) => props.row.lastActivity ? moment(props.row.lastActivity).format('DD.MM.YYYY HH:mm') : '-'
    },
    {
      field: 'actions',
      headerName: '\u00A0',
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      flex: 0.5,
      renderCell: (props) => (
        <MenuButton 
          uuid={props.row.uuid}
          stripeId={props.row.stripeId}
        />
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [])

  const { data, isFetching, isError } = useGetTrainerList({
    limit: searchParams.size,
    offset: (searchParams.page - 1) * searchParams.size,
    search: searchParams.search ? decodeURI(searchParams.search) : undefined,
    order: searchParams.order,
    from: moment(searchParams.entriesFrom, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    to: moment(searchParams.entriesTo, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    enabled: isSearchParamsValid(searchParams, trainerListColumns)
  })

  useEffect(() => {
    document.getElementById(contentContainerId)?.scrollTo(0, 0)
  }, [searchParams.page])

  useEffect(() => {
    if (!isSearchParamsValid(searchParams, trainerListColumns)) {
      setSearchParams({
        page: 1,
        size: trainerListPageSizes[0],
        order: 'createdAt:desc',
        search: undefined,
        status: undefined,
        entriesFrom: moment().format('DD.MM.YYYY'),
        entriesTo: moment().format('DD.MM.YYYY')
      }, 'replaceIn')
      return
    }

    if (searchParams.page === 1) {
      setSearchParams({
        page: 1
      }, 'replaceIn')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainerListColumns])

  if (!isSearchParamsValid(searchParams, trainerListColumns)) {
    return <></>
  }

  if (isError) {
    return (
      <Grid marginTop='10rem' width='100%'>
        <ListConnectionError 
            onReload={() => {
              queryClient.resetQueries([
                queryNames.getTrainerList,
                searchParams.order,
                searchParams.search,
                searchParams.size,
                (searchParams.page - 1) * searchParams.size,
                searchParams.status != null && searchParams.status in TrainerStatus ? searchParams.status : undefined,
                moment(searchParams.entriesFrom, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                moment(searchParams.entriesTo, 'DD.MM.YYYY').format('YYYY-MM-DD')
              ])
            }}
          />
      </Grid> 
    )
  }

  const isPageNotFound = searchParams.page > 1 && !data?.trainers?.length

  return (
    <>
      <Grid 
        container 
        flexDirection='column'
        width='100%'
        marginY='1.5rem'
        gap='1.5rem'
      >
        <DataGrid
          columns={trainerListColumns}
          rows={data?.trainers ?? []}
          rowCount={data?.totalCount ?? 0}
          noRowsOverlay={NoTrainerFound}
          autoHeight={true}
          rowHeight={40}
          loading={isFetching || isPageNotFound}
          pageSize={searchParams.size}
          onPageSizeChange={(num) => { 
            setSearchParams({ size: num }, 'replaceIn')
            queryClient.resetQueries([
              queryNames.getTrainerList,
              searchParams.order,
              searchParams.search,
              num,
              (searchParams.page - 1) * num,
              searchParams.status != null && searchParams.status in TrainerStatus ? searchParams.status : undefined,
              searchParams.entriesFrom,
              searchParams.entriesTo
            ])
          }}
          onPageChange={(page) => { 
            setSearchParams({ page: page + 1 }, 'replaceIn')
            queryClient.resetQueries([
              queryNames.getTrainerList,
              searchParams.order,
              searchParams.search,
              searchParams.size,
              page * searchParams.size,
              searchParams.status != null && searchParams.status in TrainerStatus ? searchParams.status : undefined,
              searchParams.entriesFrom,
              searchParams.entriesTo
            ])
          }}
          rowsPerPageOptions={trainerListPageSizes}
          page={searchParams.page - 1}
          getRowId={(row) => row.uuid}
          onSortModelChange={handleSortChange}
          onFilterModelChange={handleFilterChange}
          sortModel={mapTrainerListSortModel(searchParams.order)}
          filterModel={mapTrainerListFilterModel(searchParams.status)}
        />
      </Grid>
    </>
  )
}
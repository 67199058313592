import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { DataSubcodes } from '../../../services/errors/consts'
import { isDataError } from '../../../services/errors/utils'
import { HttpError } from '../../../types/common'
import { RoomList } from '../../../types/rooms'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
  active: boolean
}

export default function useChangeRoomActiveState() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t

  const mutation = useMutation((data: MutationParams) => dataApi.changeRoomActiveState(data.uuid, data.active), {
    onError: (error: AxiosError<HttpError>) => {
      if (isDataError(error, [DataSubcodes.TRAINING_ROOM_NOT_FOUND, DataSubcodes.TRAINING_ROOM_REMOVED])) {
        queryClient.resetQueries([queryNames.getRoomList])
      }
      showErrorToast(error, texts)
    },
    onSuccess: (_, variables: MutationParams) => {
      const queriesData = queryClient.getQueriesData<RoomList>([queryNames.getRoomList])
      for (const queryData of queriesData) {
        queryClient.setQueryData(queryData[0], (oldData?: RoomList) => {
          if (!oldData) {
            return oldData
          }
          const newData = [...oldData.trainingRooms]
          const index = newData.findIndex(p => p.uuid === variables.uuid)
          newData[index] = {
            ...newData[index],
            active: variables.active
          }
          return {
            totalCount: oldData.totalCount,
            trainingRooms: newData
          }
        })
      }
      toast.success(
        texts(
          variables.active 
          ? 'successes:room_activated' 
          : 'successes:room_deactivated' 
        )
      )
    }
  })

  return mutation
}

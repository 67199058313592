import { Grid } from '@mui/material'
import MainSection from '../../components/schedule/MainSection'
import TopSection from '../../components/schedule/TopSection'
import useGaPageView from '../../hooks/google/useGaPageView'

export default function SchedulePage() {
  useGaPageView('Schedule')

  return (
    <Grid 
      width='100%' 
      paddingX='.5rem'
      paddingBottom='1rem'
    >
      <TopSection />
      <MainSection />
    </Grid>
  )
}
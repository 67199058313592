import { SvgIcon, SvgIconProps } from '@mui/material'

export default function VisibilityOffIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='22px'
      height='20px'
      viewBox='0 0 22 20'
    >
      <g id="closedEyeIconForInput" transform="translate(-9.000000, -10.000000)" fill-rule="nonzero">
        <g transform="translate(9.000000, 10.000000)">
          <path d="M20.5927937,1.46323373 C20.9294379,1.12850177 20.9294379,0.585780921 20.5927937,0.251048966 C20.2561495,-0.0836829886 19.7104388,-0.0836829886 19.3737945,0.251048966 L0.984181859,18.5368263 C0.647549136,18.8715697 0.647549136,19.4141991 0.984181859,19.7489425 C1.32081458,20.0836858 1.86661726,20.0836858 2.20324998,19.7489425 L5.69154609,16.2803656 C7.20618071,16.9751095 8.94023748,17.428596 10.788505,17.428596 C13.7972699,17.428596 16.5030664,16.2269941 18.4528215,14.7389917 C19.4289633,13.9940763 20.2299443,13.1667607 20.7918609,12.3615023 C21.3430885,11.5716725 21.7073171,10.7411569 21.7073171,10.0000129 C21.7073171,9.25886884 21.3430885,8.42835324 20.7918609,7.63850057 C20.2299443,6.83329931 19.4289633,6.00593801 18.4528215,5.26097684 C18.1418077,5.02362789 17.8114849,4.79357039 17.4639219,4.57450719 L20.5927937,1.46323373 Z M14.3844721,7.63653486 L13.1224873,8.8913254 C13.2840857,9.22709736 13.3745395,9.60298366 13.3745395,10.0000129 C13.3745395,11.4201294 12.2166856,12.5714455 10.788505,12.5714455 C10.3892213,12.5714455 10.0112005,12.4815025 9.67352191,12.3208165 L8.41160606,13.5756756 C9.09298591,14.024362 9.91005786,14.2857339 10.788505,14.2857339 C13.168921,14.2857339 15.0985624,12.366988 15.0985624,10.0000129 C15.0985624,9.12652577 14.8357064,8.31406735 14.3844721,7.63653486 Z" id="Shape"></path>
          <path d="M11.580428,5.66379644 C11.6758573,5.68111671 11.7743613,5.65262837 11.8429155,5.58353095 L14.0630707,3.34586491 C14.2137306,3.19395389 14.1512119,2.93580271 13.9458908,2.87807996 C12.9581176,2.60034736 11.9082812,2.43902439 10.8183599,2.43902439 C7.8373096,2.43902439 5.15638336,3.64580741 3.2245773,5.14019409 C2.25741592,5.88837454 1.46382659,6.7193112 0.907022683,7.52799207 C0.36083216,8.32125801 0,9.15536259 0,9.89970939 C0,10.6440562 0.36083216,11.4781608 0.907022683,12.2714038 C1.38224476,12.9615745 2.02994567,13.668044 2.81082627,14.3253877 C2.92225538,14.4191628 3.0859998,14.4098656 3.18866034,14.3064491 L6.53611143,10.932383 C6.60466567,10.8632856 6.63293006,10.7640011 6.61574595,10.6678156 C6.57120847,10.418514 6.54795469,10.1618664 6.54795469,9.89970939 C6.54795469,7.52254003 8.45987774,5.59546805 10.8183599,5.59546805 C11.0784561,5.59546805 11.3330861,5.61890607 11.580428,5.66379644 Z" id="Path"></path>
        </g>
      </g>
    </SvgIcon>
  )
}
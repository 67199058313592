import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'
import { mapSelectedPlaceDtoList } from '../../../mapping/common'

const dataApi = DataApi.getInstance()

export default function useSearchLocations(search: string, active?: boolean) {
  const texts = useTranslation().t

  const query = useQuery([queryNames.searchLocations, search, active], async () => {
    const { data } = await dataApi.searchLocations(search, active)
    return mapSelectedPlaceDtoList(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}

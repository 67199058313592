import { Button, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import BaseDialog from '../customMui/BaseDialog'
import { LoadingButton } from '@mui/lab'
import TextField from '../customMui/TextField'
import useChangeAccountNameState from '../../hooks/data/accounts/useChangeAccountNameState'
import { AccountNameFormData } from '../../types/forms/data'
import { mapAccountNameFormData } from '../../mapping/accounts'
import { accountNameFormDataValidation } from '../../validations/account'
import { Account } from '../../types/account'

type Props = {
  open: boolean
  account: Account | null
  onClose: () => void
}

export default function UpdateAccountNameDialog(props: Props) {
  const texts = useTranslation('dialogs').t

  const mutation = useChangeAccountNameState()

  const handleSubmit = (data: AccountNameFormData) => {
    if (props.account != null) {
      mutation.mutate({
        uuid: props.account.uuid,
        data: mapAccountNameFormData(data)
      }, {
        onSettled: props.onClose
      })
    }
  }

  return (
    <BaseDialog
      open={props.open}
      title={texts('update_account_name_title')}
      onCloseButtonClick={props.onClose}
      sx={{
        '.MuiDialog-paper': {
          minWidth: '37.5rem'
        }
      }}
    >
      <Formik<AccountNameFormData>
        initialValues={{
          name: props.account?.name ?? ''
        }}
        validationSchema={accountNameFormDataValidation(texts)}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <DialogContent>
              <Typography
                variant='body1'
              >
                {texts('update_account_name_field_title')}
              </Typography>
              <Grid
                container
                justifyContent='center'
                marginTop='1rem'
              >
                <TextField 
                  name='name'
                  label={texts('update_account_name_field_label')}
                  sx={{
                    width: '60%'
                  }}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant='outlined'
                size='medium'
                onClick={props.onClose}
                sx={{
                  width: '10rem'
                }}
              >
                {texts('update_account_name_cancel')}
              </Button>
              <LoadingButton
                type='submit'
                variant='contained'
                size='medium'
                loading={mutation.isLoading}
                disabled={!formikProps.dirty || !formikProps.isValid}
                sx={{
                  width: '10rem'
                }}
              >
                {texts('update_account_name_confirm')}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </BaseDialog>
  )
}
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { AccountNameDto } from '../../../api/data/types'
import { updateUserName } from '../../../redux/storageToolkit'
import store from '../../../redux/store'
import { DataSubcodes } from '../../../services/errors/consts'
import { isDataError } from '../../../services/errors/utils'
import { getUserUuid } from '../../../services/storage/storageService'
import { AccountList } from '../../../types/account'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
  data: AccountNameDto
}

export default function useChangeAccountNameState() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t

  const mutation = useMutation((data: MutationParams) => dataApi.changeAccountNameState(data.uuid, data.data), {
    onError: (error: AxiosError<HttpError>) => {
      if (isDataError(error, [DataSubcodes.ACCOUNT_NOT_FOUND, DataSubcodes.ACCOUNT_REMOVED])) {
        queryClient.resetQueries([queryNames.getAccountList])
      }
      showErrorToast(error, texts)
    },
    onSuccess: (_, variables: MutationParams) => {
      if (variables.uuid === getUserUuid()) {
        store.dispatch(updateUserName(variables.data.name))
      }
      const queriesData = queryClient.getQueriesData<AccountList>([queryNames.getAccountList])
      for (const queryData of queriesData) {
        queryClient.setQueryData(queryData[0], (oldData?: AccountList) => {
          if (!oldData) {
            return oldData
          }
          const newData = [...oldData.accounts]
          const index = newData.findIndex(p => p.uuid === variables.uuid)
          newData[index] = {
            ...newData[index],
            name: variables.data.name
          }
          return {
            totalCount: oldData.totalCount,
            accounts: newData
          }
        })
      }

      toast.success(texts('successes:account_name_updated'))
    }
  })

  return mutation
}

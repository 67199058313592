import { Grid } from '@mui/material'
import MainSection from '../../components/trainers/page/MainSection'
import TopSection from '../../components/trainers/page/TopSection'
import { mapTrainer } from '../../mapping/trainers'
import { useNavigate, useParams } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'
import useGetTrainerDetails from '../../hooks/data/trainers/useGetTrainerDetails'
import { isDataError } from '../../services/errors/utils'
import { DataSubcodes } from '../../services/errors/consts'
import Loader from '../../components/common/Loader'
import ConnectionError from '../Errors/ConnectionError'
import { queryNames } from '../../hooks/queries'
import useGaPageView from '../../hooks/google/useGaPageView'

export default function TrainerPage() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { uuid } = useParams<{ uuid?: string }>()

  useGaPageView('Trainer details')

  const { isFetching, data, isError } = useGetTrainerDetails(
    uuid ?? '',
    (error) => {
      if (isDataError(error, DataSubcodes.REQUESTED_TRAINER_NOT_FOUND)) {
        navigate('/404')
      }
    },
    Boolean(uuid)
  )

  if (isFetching) {
    return <Loader />
  }

  if (isError) {
    return (
      <ConnectionError 
        onRefresh={() => {
          queryClient.resetQueries([queryNames.getTrainerDetails, uuid])
        }}
      />
    )
  }

  return (
    <Grid width='100%' paddingX='.5rem'>
      <TopSection name={`${data!.lastName} ${data!.firstName}`}/>
      <MainSection data={mapTrainer(data!)} />
    </Grid>
  )
}
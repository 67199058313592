import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import { Button, Grid, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ScheduleFilterFormData } from '../../types/forms/filter'
import FilterSection from './FilterSection'

export enum DateRangeChangeType {
  BACK,
  RESET,
  FORWARD
}

type Props = {
  initValues?: ScheduleFilterFormData
  onDateRangeChange: (type: DateRangeChangeType) => void
  onFilterChange: (data: ScheduleFilterFormData) => void
  isFilterPresent: boolean
}

export default function ControlSection(props: Props) {
  const texts = useTranslation('schedule').t

  return (
    <Grid 
      container
      marginBottom='1rem'
      width='100%'
    >
      <Grid xs={3} />
      <Grid
        container
        width='max-content'
        justifyContent='center'
        alignItems='center'
        gap='1rem'
        xs={6}
      >
        <IconButton
          sx={{
            color: '#000000',
            width: '1rem',
            height: '1rem'
          }}
          onClick={() => {
            props.onDateRangeChange(DateRangeChangeType.BACK)
          }}
        >
          <ArrowLeft fontSize='large'/>
        </IconButton>
        <Button
          variant='text'
          sx={{
            color: '#000000'
          }}
          onClick={() => {
            props.onDateRangeChange(DateRangeChangeType.RESET)
          }}
        >
          {texts('set_today')}
        </Button>
        <IconButton
          sx={{
            color: '#000000',
            width: '1rem',
            height: '1rem'
          }}
          onClick={() => {
            props.onDateRangeChange(DateRangeChangeType.FORWARD)
          }}
        >
          <ArrowRight fontSize='large'/>
        </IconButton>
      </Grid>
      <Grid 
        xs={3} 
        container 
        justifyContent='flex-end'
      >
        <FilterSection 
          initValues={props.initValues}
          onFilterChange={props.onFilterChange}
          isFilterPresent={props.isFilterPresent}
        />
      </Grid>
    </Grid>
  )
}
import { Box, Grid } from '@mui/material'
import { Room } from '../../../types/rooms'

type Props = {
  room: Room
}

export default function TileEquipment(props: Props) {
  return (
    <>
      {
        props.room.equipments.length > 0 
        && <Grid 
            container
            alignItems='center'
            gap='.625rem'
            marginTop='.5rem'
            sx={{
              opacity: props.room.active ? 1 : 0.5
            }}
          >
            {
              props.room.equipments.map(item => (
                <Box
                  key={item.uuid}
                  component='img'
                  src={item.photo!.thumbnail ?? item.photo!.original!}
                  width='2.25rem'
                  height='2.25rem'
                />
              ))
            }     
          </Grid>
      }
    </>
    
  )
}
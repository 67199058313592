import { Groups } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import moment from 'moment'
import EventTileMenu from './EventTileMenu'
import { Event } from '../../types/schedule'
import { useState } from 'react'
import RemoveEventDialog from '../dialogs/RemoveEventDialog'
import { useTranslation } from 'react-i18next'
import ReservationsOwnerListDialog from '../dialogs/ReservationsOwnerListDialog'

type Props = {
  event: Event
}

export default function EventTile(props: Props) {
  const texts = useTranslation('schedule').t
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState<boolean>(false)
  const [isReservationOwnerListDialogOpen, setIsReservationOwnerListDialogOpen] = useState<boolean>(false)

  return (
    <>
      <RemoveEventDialog
        open={isRemoveDialogOpen}
        event={props.event}
        onClose={() => { setIsRemoveDialogOpen(false) }}
      />
      <ReservationsOwnerListDialog
        open={isReservationOwnerListDialogOpen}
        event={props.event}
        onClose={() => { setIsReservationOwnerListDialogOpen(false) }}
      />
      <Grid 
        width='100%'
        container
        flexDirection='column'
        alignItems='center'
        gap='.25rem'
        sx={{
          border: `1px solid ${props.event.trainingRoom.color}${props.event.isPast ? '80' : ''}`,
          borderRadius: '.5rem',
          padding: '.5rem'
        }}
      >
        <Grid
          container
          justifyContent='flex-end'
        >
          <EventTileMenu 
            event={props.event} 
            onRemoveClick={() => { setIsRemoveDialogOpen(true) }}
            onOpenReservationOwnerList={() => { setIsReservationOwnerListDialogOpen(true) }}
          />
        </Grid>
        <Typography
          variant='body1'
          fontWeight={700}
          textAlign='center'
          sx={{ opacity: props.event.isPast ? 0.5 : 1 }}
        >
          {`${moment(props.event.start, 'YYYY-MM-DD HH:mm').format('HH:mm')}-${moment(props.event.end, 'YYYY-MM-DD HH:mm').format('HH:mm')}`}
        </Typography>
        <Typography
          variant='body2'
          fontWeight={700}
          color={props.event.trainingRoom.color}
          textAlign='center'
          sx={{
            lineHeight: '1rem',
            wordBreak: 'break-all',
            opacity: props.event.isPast ? 0.5 : 1
          }}
        >
          {texts('inactive_option_label', { active: props.event.trainingRoom.active ? 1 : 0, label: props.event.trainingRoom.name })}
        </Typography>
        <Typography
          variant='body1'
          fontSize='.625rem'
          lineHeight='1.4'
          textAlign='center'
          sx={{
            wordBreak: 'break-all',
            opacity: props.event.isPast ? 0.5 : 1
          }}
        >
          {texts('inactive_option_label', { active: props.event.place.active ? 1 : 0, label: props.event.place.name })}
        </Typography>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          gap='.5rem'
          sx={{ opacity: props.event.isPast ? 0.5 : 1 }}
        >
          <Groups sx={{ marginTop: '-.125rem' }}/>
          <Typography>
            {`${props.event.takenSeats}/${props.event.totalSeats}`}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
import { Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../components/common/Loader'
import MainSection from '../../components/rooms/page/MainSection'
import TopSection from '../../components/rooms/page/TopSection'
import useGetEquipmentList from '../../hooks/data/equipment/useGetEquipmentList'
import useGetPlaceDetails from '../../hooks/data/locations/useGetPlaceDetails'
import useGetTrainingRoomDetails from '../../hooks/data/rooms/useGetTrainingRoomDetails'
import useGaPageView from '../../hooks/google/useGaPageView'
import { queryNames } from '../../hooks/queries'
import { mapRoomDetails } from '../../mapping/rooms'
import { DataSubcodes } from '../../services/errors/consts'
import { isDataError } from '../../services/errors/utils'
import ConnectionError from '../Errors/ConnectionError'

export default function RoomPage() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  
  const { uuid, roomUuid } = useParams<{ uuid?: string; roomUuid?: string }>()

  useGaPageView(roomUuid ? 'Edit training room' : 'Add training room')

  const equipmentList = useGetEquipmentList()

  const placeDetails = useGetPlaceDetails(
    uuid ?? '',
    (error) => {
      if (isDataError(error, [DataSubcodes.PLACE_NOT_FOUND, DataSubcodes.PLACE_REMOVED])) {
        navigate('/404')
      }
    }
  )

  const roomDetails = useGetTrainingRoomDetails(
    roomUuid ?? '',
    (error) => {
      if (isDataError(error, [DataSubcodes.TRAINING_ROOM_NOT_FOUND, DataSubcodes.TRAINING_ROOM_REMOVED])) {
        navigate('/404')
      }
    },
    Boolean(roomUuid)
  )

  if (equipmentList.isFetching || placeDetails.isFetching || roomDetails.isFetching) {
    return <Loader />
  }

  if (equipmentList.isError || placeDetails.isError || roomDetails.isError) {
    return (
      <ConnectionError
        onRefresh={() => {
          if (placeDetails.isError) {
            queryClient.resetQueries([queryNames.getPlaceDetails, uuid])
          }
          if (equipmentList.isError) {
            queryClient.resetQueries([queryNames.getEquipmentList])
          }
          if (roomDetails.isError) {
            queryClient.resetQueries([queryNames.getRoomDetails, roomUuid])
          }
        }}
      />
    )
  }

  return (
    <Grid 
      width='100%' 
      paddingX='.5rem'
      paddingBottom='1rem'
    >
      <TopSection 
        placeName={placeDetails.data!.name}
        isEditPage={Boolean(roomUuid)}
      />
      <MainSection 
        isEditPage={Boolean(roomUuid)}
        equipments={equipmentList.data!}
        initialValues={roomDetails.data ? mapRoomDetails(roomDetails.data) : undefined}
      />
    </Grid>
  )
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { LoginState, forceLogOut } from '../redux/storageToolkit'
import store from '../redux/store'
import { AuthSubcodes, Codes, InternalSubcodes } from '../services/errors/consts'
import { SessionTokenDto } from './auth/types'
import moment from 'moment'

export abstract class Api {
  protected api: AxiosInstance
  protected refreshingToken: Promise<AxiosResponse<SessionTokenDto>> | null = null

  constructor (config: AxiosRequestConfig) {
    this.api = axios.create({
      ...config,
      timeout: 30 * 1000,
      withCredentials: true
    })
  
    // this middleware is been called right before the response is get it by the method that triggers the request
    this.api.interceptors.response.use(
      (response) => response,
      this.handleError
    )

    this.api.interceptors.request.use(
      (request) => {
        request.headers.set('x-device-date-time', moment().format('YYYY-MM-DD HH:mm:ss'))
        return request
      }
    )
  }

  protected getUri (config?: AxiosRequestConfig): string {
    return this.api.getUri(config)
  }

  protected request<T, D = any> (config: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    return this.api.request(config)
  }

  protected get<T, D = any> (url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    return this.api.get(url, config)
  }

  protected delete<T, D = any> (url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    return this.api.delete(url, config)
  }

  protected head<T, D = any> (url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    return this.api.head(url, config)
  }

  protected post<T, D = any> (url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    return this.api.post(url, data, config)
  }

  protected put<T, D = any> (url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    return this.api.put(url, data, config)
  }

  protected patch<T, D = any> (url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    return this.api.patch(url, data, config)
  }

  private refreshToken(): Promise<AxiosResponse<SessionTokenDto>> {
    return this.post<SessionTokenDto>('/refresh', undefined, {
      baseURL: `${process.env.REACT_APP_BASE_AUTH}/api/v1/admin/auth/account`
    })
  }

  private handleError = (error: any): Promise<Error> => {
    if (this.isForceLogout(error)) {
      store.dispatch(forceLogOut())
      error.response.data.code = Codes.INTERNAL
      error.response.data.subcode = InternalSubcodes.FORCED_LOGOUT
      return Promise.reject(error)
    }

    if (this.shouldRefreshToken(error)) {
      return this.handleRefreshToken(error)
    }
    return Promise.reject(error)
  }

  private handleRefreshToken = async (e: any): Promise<Error> => {
    const config: AxiosRequestConfig & { _retry?: boolean } = e.config
    config._retry = true
    try {
      this.refreshingToken = this.refreshingToken ?? this.refreshToken()
      await this.refreshingToken
      return this.api(config)
    } catch(e) {
      return Promise.reject(e)
    } finally {
      this.refreshingToken = null
    }
  }

  private shouldRefreshToken = (e: any): boolean => {
    const httpStatus = e.response?.data.httpStatus
    const code = e.response?.data?.code
    const subcode = e.response?.data?.subcode
    const config: AxiosRequestConfig & { _retry: boolean } = e.config

    return httpStatus === 401 && code === Codes.AUTH 
      && (subcode === AuthSubcodes.UNAUTHORIZED) 
      && !config._retry
  }

  private isForceLogout = (e: any): boolean => {
    const config: AxiosRequestConfig & { _retry?: boolean } = e.config
    const httpStatus = e.response?.data.httpStatus
    const code = e.response?.data?.code
    const subcode = e.response?.data?.subcode

    if (
      store.getState().storage.loginState === LoginState.LOGGED 
      && httpStatus === 401
      && code === Codes.AUTH 
      && subcode === AuthSubcodes.UNAUTHORIZED
      && config.url?.includes('refresh')
    ) {
      return true
    } else {
      return false
    }
  }
}
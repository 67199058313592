import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { AccountListOptionsDto } from '../../../api/data/types'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'
import { mapAccountListDto } from '../../../mapping/accounts'

const dataApi = DataApi.getInstance()

type Options = {
  enabled: boolean
}

export default function useGetAccountList(options: AccountListOptionsDto & Options) {
  const texts = useTranslation().t

  const query = useQuery([queryNames.getAccountList, options.order, options.search, options.limit, options.offset, options.status], async () => {
    const { data } = await dataApi.getAccountList(options)
    return mapAccountListDto(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}

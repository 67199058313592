import { Trans, useTranslation } from 'react-i18next'
import Dialog from '../customMui/Dialog'
import useChangeRoomActiveState from '../../hooks/data/rooms/useChangeRoomActiveState'
import { Room } from '../../types/rooms'

type Props = {
  open: boolean
  room: Room
  onClose: () => void
}

export default function UpdateRoomActiveStateDialog(props: Props) {
  const texts = useTranslation('dialogs').t

  const mutation = useChangeRoomActiveState()

  return (
    <Dialog
      open={props.open}
      title={<Trans i18nKey={props.room.active ? 'dialogs:deactivate_room_title' : 'dialogs:activate_room_title'}/>}
      description={<Trans i18nKey={props.room.active ? 'dialogs:deactivate_room_description' : 'dialogs:activate_room_description'}/>}
      warning={<Trans i18nKey={props.room.active ? 'dialogs:deactivate_room_warning' : 'dialogs:activate_room_warning'}/>}
      closeButtonLabel={texts(props.room.active ? 'deactivate_room_cancel' : 'activate_room_cancel')}
      confirmButtonLabel={texts(props.room.active ? 'deactivate_room_confirm' : 'activate_room_confirm')}
      loading={mutation.isLoading}
      onCloseButtonClick={props.onClose}
      onConfirmButtonClick={() => {
        mutation.mutate({
          uuid: props.room.uuid,
          active: !props.room.active
        }, {
          onSettled: props.onClose
        })
      }}
    />
  )
}

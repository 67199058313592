import { Grid, useTheme } from '@mui/material'
import { useState, useEffect } from 'react'
import { LoginState } from '../../redux/storageToolkit'
import store from '../../redux/store'
import { getUserName } from '../../services/storage/storageService'
import MenuDrawer from '../customMui/menuDrawer/MenuDrawer'
import { maxPageWidth } from '../themes/MainTheme'
import AppBar from './AppBar'
import { contentContainerId } from '../../utils/const'
import ScrollToTop from '../common/ScrollToTop'

type Props = {
  children: React.ReactNode
}

export default function MainOverlay(props: Props) {
  const theme = useTheme()

  const [drawerExpanded, setDrawerExpanded] = useState(false)
  const [userName, setUserName] = useState<string>(getUserName() ?? '')

  const handleDrawerSwitch = () => {
    setDrawerExpanded(!drawerExpanded)
  }

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      if (userName !== store.getState().storage.userName) {
        setUserName(store.getState().storage.userName)
      }
    })
  
    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid
      container
      flexDirection='column'
      width='100%'
      height='100vh'
      maxHeight='100vh'
      alignItems='center'
      overflow='hidden'
      flexWrap='nowrap'
    > 
      <AppBar 
        loggedIn={store.getState().storage.loginState === LoginState.LOGGED}
        userName={userName}
      />
      <Grid 
        item
        container
        flexGrow={1}
        flexWrap='nowrap'
        width='100%'
      >
        {
          store.getState().storage.loginState === LoginState.LOGGED
          ? <>
              <MenuDrawer
                onSwitchClick={handleDrawerSwitch}
                expanded={drawerExpanded}
              />
              <Grid
                id={contentContainerId}
                container 
                item
                width={`calc(100% - ${drawerExpanded ? 18.5 : 5}rem)`}
                flexGrow={1}
                justifyContent='center'
                maxHeight='calc(100vh - 3.5rem)'
                sx={{
                  float: 'right',
                  overflowY: 'auto',
                  backgroundColor: '#FAFAFA',
                  transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: drawerExpanded ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen,
                  }),
                }}
              >
                <ScrollToTop containerId={contentContainerId} />
                <Grid 
                  item
                  width='100%'
                  maxWidth={maxPageWidth}
                >                
                  {props.children}
                </Grid>
              </Grid>
            </>
          : props.children 
        }
      </Grid>
    </Grid>
  )
}
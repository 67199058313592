import { Grid } from '@mui/material'
import TopSection from '../../components/accounts/create/TopSection'
import MainSection from '../../components/accounts/create/MainSection'
import useGaPageView from '../../hooks/google/useGaPageView'

export default function CreateAccount() {
  useGaPageView('Add admin user')

  return (
    <Grid width='100%' paddingX='.5rem'>
      <TopSection />
      <MainSection />
    </Grid>
  )
}
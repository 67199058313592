import { Area } from 'react-easy-crop/types'

type Resize = {
  width: number
  height: number
}

export type ImageDimensions = {
  width: number
  height: number
}

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  })

export const readFileAsDataUrl = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: Area,
  flip = { horizontal: false, vertical: false },
  resize?: Resize
): Promise<Blob | null> => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = {
    width: (image as HTMLImageElement).width,
    height: (image as HTMLImageElement).height
  }

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  ctx!.fillStyle = '#fff'
  ctx!.fillRect(0, 0, bBoxWidth, bBoxHeight)

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-(image as HTMLImageElement).width / 2, -(image as HTMLImageElement).height / 2)

  // draw rotated image
  ctx.drawImage((image as HTMLImageElement), 0, 0)

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  )

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0)

  // As Base64 string
  // return canvas.toDataURL('image/jpeg')

  if (resize) {
    const oc = document.createElement('canvas')
    const octx = oc.getContext('2d')
    oc.width = resize.width
    oc.height = resize.height
    octx!.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, resize.width, resize.height);
    return new Promise((resolve, reject) => {
      oc.toBlob((file) => {
        resolve(file)
      }, 'image/jpeg')
    })
  } else {
    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        resolve(file)
      }, 'image/jpeg')
    })
  }

  // As a blob
  
}

export async function getImageFileDimensions(file: File): Promise<ImageDimensions> {
  return new Promise(async (resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener('load', (e) => {
      const img = new Image()
      img.src = e.target?.result as string
      img.onload = function () {
        resolve({
          width: img.naturalWidth ?? img.width,
          height: img.naturalHeight ?? img.height
        })
      }
    }, false)
  })
}
import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { useQueryClient } from '@tanstack/react-query'
import { queryNames } from '../../../hooks/queries'
import SearchInput from '../../customMui/SearchInput'
import { useNavigate } from 'react-router-dom'
import { LocationListSearchParams } from '../../../types/locations'

export default function TopSection() {
  const texts = useTranslation('locationList').t
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [searchParams, setSearchParams] = useQueryParams<LocationListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    search: withDefault(StringParam, undefined)
  })

  return (
    <>
      <Grid
        container
        justifyContent='space-between'
        marginTop='2rem'
      >
        <Typography variant='h2'>
          {texts('title')}
        </Typography>
        <Button
          variant='contained'
          onClick={() => {
            navigate('/locations/create')
          }}
          sx={{
            width: '17.5rem'
          }}
        >
          {texts('add_location_button_label')}
        </Button>
      </Grid>
      <Grid sx={{ maxWidth: '40%', marginTop: '.875rem' }}>
        <SearchInput
          placeholder={texts('search_input_placeholder')}
          initValue={searchParams.search ? decodeURI(searchParams.search) : undefined}
          onSearch={(search: string) => {
            queryClient.resetQueries([queryNames.getPlaceList, search ? encodeURI(search) : undefined])
            setSearchParams({ search: search ? encodeURI(search) : undefined, page: 1 }, 'replaceIn')
          }}
          onClear={() => {
            queryClient.resetQueries([queryNames.getPlaceList, undefined])
            setSearchParams({ search: undefined, page: 1 }, 'replaceIn')
          }}
        />
      </Grid>
    </>
  )
}
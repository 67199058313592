import ClearIcon from '@mui/icons-material/Clear'
import { Autocomplete, TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { AutocompleteBaseOption } from '../../types/common'

type Props<T> = {
  name: string
  label: string
  disabled?: boolean
  options: T[]
  loading?: boolean
  onInputChange: (e: React.SyntheticEvent<Element, Event>, value: string) => void
  onChange: (value: T | string | null) => void
  getOptionLabel?: (option: T) => string
}

export default function FormikExternalAutocomplete<T extends AutocompleteBaseOption = AutocompleteBaseOption>(props: Props<T>) {
  const texts = useTranslation('common').t
  const [field, meta, helpers] = useField(props.name)

  return (
    <Autocomplete<T, false, false, false>
      loading={props.loading}
      loadingText={texts('loading_placeholder')}
      noOptionsText={texts('no_options_placeholder')}
      value={field.value}
      options={props.options}
      disabled={props.disabled}
      filterOptions={(options) => options}
      getOptionLabel={(option: T | string) => {
        return typeof option === 'string'
          ? option
          : (props.getOptionLabel?.(option) ?? option.label)
      }}
      onChange={(_, value: T | string | null) => {
        if (typeof value === 'string') {
          return
        }
        helpers.setValue(value ?? null)
        props.onChange(value)
      }}
      onInputChange={props.onInputChange}
      clearIcon={<ClearIcon />}
      renderInput={(params: TextFieldProps) => (
        <MuiTextField
          {...params}
          name={props.name}
          onBlur={field.onBlur}
          label={props.label}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && !!meta.error ? meta.error : ' '}
        />
      )}
    />
  )
}
import { Trans, useTranslation } from 'react-i18next'
import useRemoveException from '../../hooks/data/exceptions/useRemoveException'
import { Exception } from '../../types/exceptions'
import Dialog from '../customMui/Dialog'

type Props = {
  open: boolean
  exception: Exception
  onClose: () => void
}

export default function RemoveExceptionDialog(props: Props) {
  const texts = useTranslation('dialogs').t

  const mutation = useRemoveException()

  return (
    <Dialog
      open={props.open}
      title={<Trans i18nKey='dialogs:remove_exception_title'/>}
      description={<Trans i18nKey='dialogs:remove_exception_description'/>}
      warning={<Trans i18nKey='dialogs:remove_exception_warning'/>}
      closeButtonLabel={texts('remove_exception_cancel')}
      confirmButtonLabel={texts('remove_exception_confirm')}
      loading={mutation.isLoading}
      onCloseButtonClick={() => {
        props.onClose()
      }}
      onConfirmButtonClick={() => {
        mutation.mutate({
          uuid: props.exception.uuid,
        }, {
          onSettled: props.onClose
        })
      }}
    />
  )
}

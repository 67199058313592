import { Dialog as MuiDialog, SxProps, Theme, Typography } from '@mui/material'
import { ReactElement, ReactNode } from 'react'

type Props = {
  open: boolean
  title: string | ReactElement
  onCloseButtonClick?: () => void
  children: ReactNode | ReactNode[]
  sx?: SxProps<Theme>
}

export default function BaseDialog(props: Props) {
   return (
    <MuiDialog open={props.open} onClose={props.onCloseButtonClick} sx={props.sx}>
      <Typography variant='h3'>
        {props.title}
      </Typography>
      {props.children}
    </MuiDialog>
  )
}
import { TFuncKey, Namespace } from 'i18next'
import { QueryParamConfig } from 'use-query-params'

export type Success<T = any> = {
  success: true
  data?: T
}

export type HttpError = {
  httpStatus: number
  code: number
  subcode: number
  message: string
  data?: any
}

export type PreviewPhoto = {
  original: string
  thumbnail: string | null
}

export type Photo = {
  original: {
    uuid: string
    url: string
  }
  thumbnail: {
    uuid: string
    url: string
  } | null
}

// BUG! 
// Cannot limit mime type to specific extensions (or limit it with empty mime type)
// In our case it allowes all JPEG extensions such as .jfif or .pjp
// https://github.com/react-dropzone/react-dropzone/issues/1265
export const AllowedImageTypes = {
  'image/jpeg': [],
  'image/png': [],
  'image/webp': []
}

export type Coordinates = {
  lng: number
  lat: number
}

export enum AccountRoleName {
  SYSTEM_ADMIN = 'systemAdmin'
}

export enum AccountStatus {
  INACTIVE,
  WAITING_FOR_ACTIVATION,
  ACTIVE
}

export type AutocompleteBaseOption = {
  value: string
  label: string
}

export type SelectOption = {
  value: number | string
  label?: string
  i18?: {
    text: TFuncKey<Namespace>
    params?: { [key in string]: string | number }
  }
}

export type ToggleButtonOption = {
  value: number | string
  label?: string
  i18?: {
    text: TFuncKey<Namespace>
    params?: { [key in string]: string | number }
  }
}

export enum PatternRepeatType {
  NO_REPEAT,
  EVERY_DAY,
  EVERY_WEEK
}

export enum LocationState {
  UNDEFINED,
  ACTIVE,
  INACTIVE
}

export enum PatternPageReferrer {
  SCHEDULE,
  PATTERN_LIST
}

export const patternRepeatSelectOptions: SelectOption[] = [
  {
    value: PatternRepeatType.NO_REPEAT,
    i18: {
      text: 'eventPatternPage:pattern_repeat_type_label',
      params: {
        type: PatternRepeatType.NO_REPEAT
      }
    }
  },
  {
    value: PatternRepeatType.EVERY_DAY,
    i18: {
      text: 'eventPatternPage:pattern_repeat_type_label',
      params: {
        type: PatternRepeatType.EVERY_DAY
      }
    }
  },
  {
    value: PatternRepeatType.EVERY_WEEK,
    i18: {
      text: 'eventPatternPage:pattern_repeat_type_label',
      params: {
        type: PatternRepeatType.EVERY_WEEK
      }
    }
  }
]

export const weekDaysToggleButtonOptions: ToggleButtonOption[] = [
  {
    value: 1,
    i18: {
      text: 'eventPatternPage:week_day_label',
      params: {
        day: 1
      }
    }
  },
  {
    value: 2,
    i18: {
      text: 'eventPatternPage:week_day_label',
      params: {
        day: 2
      }
    }
  },
  {
    value: 3,
    i18: {
      text: 'eventPatternPage:week_day_label',
      params: {
        day: 3
      }
    }
  },
  {
    value: 4,
    i18: {
      text: 'eventPatternPage:week_day_label',
      params: {
        day: 4
      }
    }
  },
  {
    value: 5,
    i18: {
      text: 'eventPatternPage:week_day_label',
      params: {
        day: 5
      }
    }
  },
  {
    value: 6,
    i18: {
      text: 'eventPatternPage:week_day_label',
      params: {
        day: 6
      }
    }
  },
  {
    value: 0,
    i18: {
      text: 'eventPatternPage:week_day_label',
      params: {
        day: 0
      }
    }
  }
]

export const locationStateSelectOptions: SelectOption[] = [
  {
    value: LocationState.ACTIVE,
    i18: {
      text: 'schedule:location_state_label',
      params: {
        type: LocationState.ACTIVE
      }
    }
  },
  {
    value: LocationState.UNDEFINED,
    i18: {
      text: 'schedule:location_state_label',
      params: {
        type: LocationState.UNDEFINED
      }
    }
  }
]

export type Uuid = {
  uuid: string
}

export type SelectedLocation = {
  uuid: string
  name: string
  tz: string
  active: boolean
  trainingRooms: LocationRoom[]
}

export type LocationRoom = {
  uuid: string
  name: string
  active: boolean
}

export type BaseListSearchParams = {
  page: QueryParamConfig<number | null | undefined, number>
}

export enum AccountType {
  ADMIN = 'admin',
  USER = 'user',
  TRAINER = 'trainer',
  SYSTEM = 'system'
}
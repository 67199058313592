import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { EventPatternBodyDto } from '../../../api/data/types'
import { mapEventPatternDto } from '../../../mapping/eventPatterns'
import { DataSubcodes } from '../../../services/errors/consts'
import { isDataError } from '../../../services/errors/utils'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  data: EventPatternBodyDto
}

export default function useCreateEventPattern() {
  const queryClient = useQueryClient()
  const texts = useTranslation().t

  const mutation = useMutation(async (params: MutationParams) => {
    const { data } = await dataApi.createEventPattern(params.data)
    return mapEventPatternDto(data.data!)
  }, {
    onError: (error: AxiosError<HttpError>) => {
      if (isDataError(error, [DataSubcodes.PLACE_NOT_FOUND, DataSubcodes.PLACE_REMOVED])) {
        queryClient.resetQueries([queryNames.getLocationList])
        queryClient.resetQueries([queryNames.searchLocations])
      }
      showErrorToast(error, texts)
    },
    onSuccess: () => {
      queryClient.resetQueries([queryNames.getEventPatternList])
      queryClient.resetQueries([queryNames.getEventList])
      toast.success(texts('successes:event_pattern_created'))
    }
  })

  return mutation
}

import moment from 'moment'
import { ExceptionListDto, ExceptionDto, CreateExceptionDto, EditExceptionDto } from '../api/data/types'
import { LocationRoom } from '../types/common'
import { ExceptionList, Exception } from '../types/exceptions'
import { ExceptionFormData } from '../types/forms/data'
import { mapLocationRoom, mapSelectedPlaceDto } from './common'

export function mapExceptionListDto(data: ExceptionListDto): ExceptionList {
  return {
    totalCount: data.totalCount,
    exceptions: data.exceptions.map(mapExceptionDto)
  }
}

export function mapExceptionDto(data: ExceptionDto): Exception {
  return {
    uuid: data.uuid,
    scheduledAt: data.scheduledAt,
    place: mapSelectedPlaceDto(data.place),
    withTime: !data.allDay,
    unavailableFrom: data.startTime ?? null,
    unavailableTo: data.endTime ?? null
  }
}

export function mapExceptionFormDataForCreate(data: ExceptionFormData): CreateExceptionDto {
  return {
    scheduledAt: moment(data.scheduledAt, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    allDay: !data.withTime,
    ...(data.withTime ? {
      startTime: data.unavailableFrom!,
      endTime: data.unavailableTo!
    } : {}),
    place: {
      uuid: data.location!.value,
      trainingRoomUuids: data.rooms.map(e => e.value)
    },
  }
}

export function mapExceptionFormDataForEdit(data: ExceptionFormData): EditExceptionDto {
  return {
    place: {
      uuid: data.location!.value,
      trainingRoomUuids: data.rooms.map(e => e.value)
    },
  }
}

export function mapException(data: Exception, availableRooms: LocationRoom[]): ExceptionFormData {
  return {
    scheduledAt: moment(data.scheduledAt, 'YYYY-MM-DD').format('DD.MM.YYYY'),
    location: {
      value: data.place.uuid,
      label: data.place.name,
      tz: data.place.tz,
      active: data.place.active,
      trainingRooms: availableRooms.map(mapLocationRoom)
    },
    rooms: data.place.trainingRooms.map(mapLocationRoom),
    withTime: data.withTime,
    unavailableFrom: data.unavailableFrom,
    unavailableTo: data.unavailableTo
  }
}
import { LoadingButton } from '@mui/lab'
import { Card, CardContent, Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import TextField from '../../customMui/TextField'
import useCreateAdminAccount from '../../../hooks/data/accounts/useCreateAdminAccount'
import { CreateAccountFormData } from '../../../types/forms/data'
import { mapCreateAccountFormData } from '../../../mapping/accounts'
import { createAccountFormDataValidation } from '../../../validations/account'

const defaultInitialValues: CreateAccountFormData = {
  name: '',
  email: ''
}

export default function MainSection() {
  const texts = useTranslation('createAccount').t
  const navigate = useNavigate()

  const mutation = useCreateAdminAccount()

  const handleSubmit = (data: any) => {
    mutation.mutate({
      data: mapCreateAccountFormData(data)
    }, {
      onSuccess: () => {
        navigate('/accounts', { replace: true })
      }
    })
  }

  return (
    <Grid
      container
      justifyContent='center'
    >
      <Card 
        sx={{ 
          width: '80%',
          '.MuiCardContent-root': {
            padding: '2rem',
            ':last-child': {
              padding: '2rem'
            }
          }
        }}
      >
        <CardContent>
          <Formik<CreateAccountFormData>
            initialValues={defaultInitialValues}
            onSubmit={handleSubmit}
            validationSchema={createAccountFormDataValidation(texts)}
          >
            {(formikProps) => (
              <Form>
                <Grid xs={7}>
                  <TextField
                    name='name'
                    label={texts('name_label')}
                  />
                  <TextField
                    name='email'
                    label={texts('email_label')}
                  />
                </Grid>
                <Grid
                  container
                  justifyContent='flex-end'
                >
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    disabled={!formikProps.dirty || !formikProps.isValid}
                    loading={mutation.isLoading}
                    sx={{
                      width: '10rem'
                    }}
                  >
                    {texts('create_button')}
                  </LoadingButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Grid>
  )
}
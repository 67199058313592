import { Trans, useTranslation } from 'react-i18next'
import Dialog from '../customMui/Dialog'
import useChangePlaceActiveState from '../../hooks/data/locations/useChangePlaceActiveState'
import { Location } from '../../types/locations'

type Props = {
  open: boolean
  location: Location
  onClose: () => void
}

export default function UpdateLocationActiveStateDialog(props: Props) {
  const texts = useTranslation('dialogs').t

  const mutation = useChangePlaceActiveState()

  return (
    <Dialog
      open={props.open}
      title={<Trans i18nKey={props.location.active ? 'dialogs:deactivate_location_title' : 'dialogs:activate_location_title'}/>}
      description={<Trans i18nKey={props.location.active ? 'dialogs:deactivate_location_description' : 'dialogs:activate_location_description'}/>}
      warning={<Trans i18nKey={props.location.active ? 'dialogs:deactivate_location_warning' : 'dialogs:activate_location_warning'}/>}
      closeButtonLabel={texts(props.location.active ? 'deactivate_location_cancel' : 'activate_location_cancel')}
      confirmButtonLabel={texts(props.location.active ? 'deactivate_location_confirm' : 'activate_location_confirm')}
      loading={mutation.isLoading}
      onCloseButtonClick={props.onClose}
      onConfirmButtonClick={() => {
        mutation.mutate({
          uuid: props.location.uuid,
          active: !props.location.active
        }, {
          onSettled: props.onClose
        })
      }}
    />
  )
}

import { SvgIcon, SvgIconProps } from '@mui/material'

export default function ImageIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='39px'
      height='36px'
      viewBox='0 0 39 36'
    >
      <g id="Symbols" stroke="none" stroke-width="1" fill-rule="evenodd">
        <g id="imageIcon" fill-rule="nonzero">
            <path d="M10.2262028,28.6364045 C7.6065774,28.6364045 5.27639007,26.9591012 4.43024775,24.4620225 L4.37301934,24.2738577 C4.17346874,23.6125842 4.08987322,23.0564794 4.08987322,22.5000749 L4.08987322,11.3432209 L0.120135405,24.5944569 C-0.390426393,26.5435206 0.773019321,28.5641948 2.72507927,29.1026217 L28.0281504,35.8789513 C28.3439557,35.960749 28.6597609,36 28.9707721,36 C30.6004351,36 32.0895737,34.918352 32.5069519,33.3243445 L33.9811092,28.6364045 L10.2262028,28.6364045 Z" id="Path"></path>
            <path d="M14.7262777,11.4546816 C16.5312216,11.4546816 17.9987871,9.98681643 17.9987871,8.18187261 C17.9987871,6.37692879 16.5312216,4.90906368 14.7262777,4.90906368 C12.9213339,4.90906368 11.4534687,6.37692879 11.4534687,8.18187261 C11.4534687,9.98681643 12.9213339,11.4546816 14.7262777,11.4546816 Z" id="Path"></path>
            <path d="M35.18051,0 L10.6351916,0 C8.38050993,0 6.54440506,1.83610487 6.54440506,4.09108611 L6.54440506,22.0907865 C6.54440506,24.3457678 8.38050993,26.1818726 10.6351916,26.1818726 L35.18051,26.1818726 C37.4354913,26.1818726 39.2715961,24.3457678 39.2715961,22.0907865 L39.2715961,4.09108611 C39.2715961,1.83610487 37.4354913,0 35.18051,0 L35.18051,0 Z M10.6351916,3.27280893 L35.18051,3.27280893 C35.6323452,3.27280893 35.9987871,3.6392509 35.9987871,4.09108611 L35.9987871,15.7075656 L30.8296486,9.67580523 C30.281334,9.03280894 29.4876261,8.68913859 28.6351916,8.66966287 C27.7875512,8.67445688 26.9923452,9.05078647 26.4491242,9.70217228 L20.3715213,16.9968539 L18.3915962,15.0217228 C17.272495,13.9026217 15.4510718,13.9026217 14.3334688,15.0217228 L9.81721407,19.5364794 L9.81721407,4.09108611 C9.81721407,3.6392509 10.183656,3.27280893 10.6351916,3.27280893 Z" id="Shape"></path>
        </g>
    </g>
    </SvgIcon>
  )
}
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { mapReservationOwnerDtoList } from '../../../mapping/schedule'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type Options = {
  enabled: boolean
}

export default function useGetReservationsOwners(uuid: string, options: Options) {
  const texts = useTranslation().t

  const query = useQuery([queryNames.getReservationsOwners, uuid], async () => {
    const { data } = await dataApi.getEventReservations(uuid)
    return mapReservationOwnerDtoList(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    initialData: [],
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}

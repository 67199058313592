import * as Yup from 'yup'
import { TFunction, Namespace } from 'i18next'
import { emailRegex, phoneRegex, postalCodeRegex } from './regexp'

export function locationFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    name: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .max(64, t('validations:max_64_chars')),
    street: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .max(64, t('validations:max_64_chars')),
    postalCode: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .matches(postalCodeRegex, t('validations:incorrect_field_format_short')),
    town: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .max(64, t('validations:max_64_chars')),
    contactEmail: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .matches(emailRegex, t('validations:incorrect_field_format'))
      .max(64, t('validations:max_64_chars')),
    contactPhone: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .matches(phoneRegex, t('validations:incorrect_field_format')),
    directions: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .max(256, t('validations:max_256_chars')),
    lockId: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .max(128, t('validations:max_128_chars')),
    coordinates: Yup.object()
      .required(t('validations:field_required'))
  })
}
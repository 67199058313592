import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import AuthApi from '../../api/auth/AuthApi'
import { EmailDto } from '../../api/auth/types'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/errors'
import { useNavigate } from 'react-router-dom'

const authApi = AuthApi.getInstance()

export function useRequestPwdReset() {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const mutation = useMutation((data: EmailDto) => authApi.requestPasswordReset(data), {
    onSuccess: () => {
      toast.success(texts('successes:request_pwd_reset'))
      navigate('/')
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { PlaceBodyDto } from '../../../api/data/types'
import { mapPlaceDetailsDto } from '../../../mapping/locations'
import { DataSubcodes } from '../../../services/errors/consts'
import { isDataError } from '../../../services/errors/utils'
import { HttpError } from '../../../types/common'
import { LocationDetails } from '../../../types/locations'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type MutationParams = {
  data: PlaceBodyDto
}

export default function useEditPlace(uuid: string) {
  const queryClient = useQueryClient()
  const texts = useTranslation().t

  const mutation = useMutation(async (params: MutationParams) => {
    const { data } = await dataApi.editPlace(uuid, params.data)
    return mapPlaceDetailsDto(data.data!)
  }, {
    onError: (error: AxiosError<HttpError>) => {
      if (isDataError(error, [DataSubcodes.PLACE_NOT_FOUND, DataSubcodes.PLACE_REMOVED])) {
        queryClient.resetQueries([queryNames.getPlaceList])
        queryClient.removeQueries([queryNames.getPlaceDetails, uuid])
      }
      showErrorToast(error, texts)
    },
    onSuccess: (data: LocationDetails) => {
      queryClient.setQueryData([queryNames.getPlaceDetails, uuid], data)
      queryClient.resetQueries([queryNames.getPlaceList]) // Reset because there is additional count for list that is not in details
      toast.success(texts('successes:location_updated'))
    }
  })

  return mutation
}
